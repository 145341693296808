import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import useReactRouter from "use-react-router";
import { Col, Modal, Button } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from '../../common/Loading'
import {
	CREATE_STUDENT,
	UPDATE_STUDENT,
} from "../../apollo/registrationstudent";
import { onConvertMaritualStatus, onConvertStudentInfo, onConvertEducationLevel, onConvertGenderEn, convertGender, convertHealthStatus } from "../../common/super";
import { STUDENTS, TRIBES } from "../../apollo/students";

// CourseAddConfirm
function RegistrationConfirm({
	param,
	studentType,
	showAddConfirmModal,
	_handleShowAddConfirmModalClose,
}) {
	const { history, } = useReactRouter();
	const [dataQuey, setDataQuey] = useState();
	const [createStudent, { loading }] = useMutation(
		CREATE_STUDENT
	);
	const [loadeTribe, { data: dataTribe }] = useLazyQuery(TRIBES);
	const [updateStudent, { data: studentData }] = useMutation(UPDATE_STUDENT);
	const [show, setShow] = useState(false);
	const [tribeName, setTribeName] = useState('');
	const [assessmentYear, setAssessmentYear] = useState("")
	const [loadStudentData, { data: dataStudent }] = useLazyQuery(STUDENTS);

	useEffect(() => {
		const NOW = new Date().getFullYear();
		const checkMonth = new Date().getMonth() + 1
		let year
		if (checkMonth === 8 || checkMonth === 9 || checkMonth === 10 || checkMonth === 11 || checkMonth === 12) {
			year = NOW + " - " + (parseInt(NOW) + 1);
		} else {
			year = (NOW - 1) + " - " + NOW;
		}
		// setAssessmentYear(year)
		setAssessmentYear("2024 - 2025")
	}, [])

	useEffect(() => {
		if (_.isEmpty(param) === true) return;
		loadStudentData({
			variables: {
				where: { code: param?.data?.code, assessmentYear: assessmentYear },
			},
		});
		loadeTribe()
	}, [param, assessmentYear]);

	useEffect(() => {
		setDataQuey(dataStudent?.students?.data[0]);
		const index = _.findIndex(dataTribe?.tribes, {
			id: param?.data?.tribe
		})
		setTribeName(dataTribe?.tribes[index]?.trib_name)
	}, [dataStudent, dataTribe]);

	// SEND_FORM
	const confirmRegistration = async () => {

		try {
			if (dataQuey?.statusStep === "SAVED") {
				delete param.data.facultyname;
				delete param.data.departmentname;
				delete param.data.majorname;
				// delete param.data.statusStepUpdate;
				const updateData = await updateStudent({
					variables: {
						where: { id: dataQuey?.id },
						data: param.data,
					},
				});
				if (updateData?.data?.updateStudent?.id) {
					_handleShowAddConfirmModalClose();
					setShow(true);
					setTimeout(() => {
						setShow(false);
						history.push(`/cardstudent/${param?.data?.code}`);
						window.location.reload();
					}, 1000);
				}
			} else if (dataQuey?.statusStep === "REJECTED") {
				delete param.data.facultyname;
				delete param.data.departmentname;
				delete param.data.majorname;
				// delete param.data.statusStepUpdate;
				const updateData = await updateStudent({
					variables: {
						where: { id: dataQuey?.id },
						data: param.data,
					},
				});
				if (updateData?.data?.updateStudent?.id) {
					_handleShowAddConfirmModalClose();
					setShow(true);
					setTimeout(() => {
						setShow(false);
						history.push(`/cardstudent/${param?.data?.code}`);
						window.location.reload();
					}, 1000);
				}
			} else {
				delete param.data.facultyname;
				delete param.data.departmentname;
				delete param.data.majorname;
				const createNewStudent = await createStudent({ variables: param });
				if (createNewStudent?.data?.createStudent?.id) {
					_handleShowAddConfirmModalClose();
					setShow(true);
					setTimeout(() => {
						setShow(false);
						history.push(`/cardstudent/${param?.data?.code}`);
						window.location.reload();
					}, 1000);
				}
			}
		} catch (err) {
			console.error("err:", err.message);
			if (err.message == "GraphQL error: EMAIL_IS_ALREADY_EXITS") {
				alert("Email ນີ້ມີຢູ່ແລ້ວ");
			}
			if (err.message == "GraphQL error: PHONE_NUMBER_IS_ALREADY_EXITS") {
				alert("ເບີໂທນີ້ ນີ້ມີຢູ່ແລ້ວ");
			}
		}
	};
	if (loading) return <Loading />
	return (
		<div>
			<Modal
				// style={{ marginTop: 50 }}
				show={showAddConfirmModal}
				onHide={_handleShowAddConfirmModalClose}
				size="xl"
				centered
			>
				<Modal.Header closeButton>
					<Col
						style={{
							marginTop: 20,
							fontSize: 30,
							fontWeight: "bold",
							color: "#057CAE",
						}}
					>
						ຢືນ​ຢັນ​ການສົ່ງ​ຂໍ້​ມູນ
					</Col>
				</Modal.Header>
				<Modal.Body>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
						</li>
						<li className="list-group-item">
							ລະ​ຫັດ​ນັກ​ສຶກ​ສາ:{" "}
							<span className="float-right">{param?.data?.studentNumber}</span>
						</li>
						<li className="list-group-item">
							ຄະ​ນ​ະ:{" "}
							<span className="float-right">{param?.data?.facultyname}</span>
						</li>
						<li className="list-group-item">
							ພາກວິຊາ:{" "}
							<span className="float-right">{param?.data?.departmentname}</span>
						</li>
						<li className="list-group-item">
							ສາ​ຂາ​ວິ​ຊາ:{" "}
							<span className="float-right">{param?.data?.majorname}</span>
						</li>
						<li className="list-group-item">
							ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.studentInfo
										? onConvertStudentInfo(param?.data?.studentInfo)
										: "-")}
							</span>
						</li>
						<li className="list-group-item">
							ລະ​ດັບ:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.educationLevel
										? onConvertEducationLevel(param?.data?.educationLevel)
										: "-")}
							</span>
						</li>
						<ul className="list-group list-group-flush">
							<li
								className="list-group-item bg-active"
								style={{ color: "#fff", fontWeight: "bold" }}
							>
								<FontAwesomeIcon icon={faCaretDown} /> ຂໍ້ມູນ​ທົ່ວໄປ
							</li>

							<li className="list-group-item">
								ຊື່:{" "}
								<span className="float-right">{param?.data?.firstNameL}</span>
							</li>
						</ul>
						<li className="list-group-item">
							ນາມ​ສະ​ກຸນ:{" "}
							<span className="float-right">{param?.data?.lastNameL}</span>
						</li>
						<li className="list-group-item">
							ຊື່ (​ພາ​ສາ​ອັງ​ກິດ){" "}
							<span className="float-right">{onConvertGenderEn(param?.data?.gernder)} {param?.data?.firstNameE}</span>
						</li>
						<li className="list-group-item">
							ນາມ​ສະ​ກຸນ (​ພາ​ສາ​ອັງ​ກິດ){" "}
							<span className="float-right">{param?.data?.lastNameE}</span>
						</li>
						<li className="list-group-item">
							ວັນ​ເດືອນ​ປີ​ເກີດ:{" "}
							<span className="float-right">{param?.data?.birthday}</span>
						</li>
						<li className="list-group-item">
							ເພດ:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.gender ? convertGender(param?.data?.gender) : "-")}
							</span>
						</li>
						<li className="list-group-item">
							ສຸຂະພາບ:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.healthStatus
										? convertHealthStatus(param?.data?.healthStatus)
										: "-")}
							</span>
						</li>
						{param && param?.data?.healthStatus === "BAD" ?
							<li className="list-group-item">
								<span>
									{param &&
										(param?.data?.health
											? param?.data?.health
											: "-")}
								</span>
							</li> : ""
						}
						<li className="list-group-item">
							ສະ​ຖາ​ນະ:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.maritualStatus
										? onConvertMaritualStatus(param?.data?.maritualStatus)
										: "-")}
							</span>
						</li>
						<li className="list-group-item">
							ສັນ​ຊາດ:{" "}
							<span className="float-right">{param?.data?.nationality}</span>
						</li>
						<li className="list-group-item">
							ຊົນ​ເຜົ່າ:{" "}
							<span className="float-right">{tribeName}</span>
						</li>
						<li className="list-group-item">
							ສາ​ສະ​ໜາ:{" "}
							<span className="float-right">{param?.data?.religion}</span>
						</li>
						<li className="list-group-item">
							ເບີໂທເຮືອນ:{" "}
							<span className="float-right">{param?.data?.phoneHome}</span>
						</li>
						<li className="list-group-item">
							ເບີໂທລະສັບມືຖື:{" "}
							<span className="float-right">{param?.data?.phoneMobile}</span>
						</li>
						<li className="list-group-item">
							ອີເມວ: <span className="float-right">{param?.data?.email}</span>
						</li>
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> ທີ່ຢູ່ປະຈຸບັນ
						</li>
						<li className="list-group-item">
							ແຂວງ:{" "}
							<span className="float-right">
								{param?.data?.address?.province}
							</span>
						</li>
						<li className="list-group-item">
							ເມືອງ:{" "}
							<span className="float-right">
								{param?.data?.address?.district}
							</span>
						</li>
						<li className="list-group-item">
							ບ້ານ:{" "}
							<span className="float-right">
								{param?.data?.address?.village}
							</span>
						</li>
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> ສະຖານທີ່ເກີດ
						</li>
						<li className="list-group-item">
							ເປັນນັກສຶກສາເກີດໃນຕ່າງປະເທດ:
							<span className="float-right">
								{param?.data?.bornAbroad === "" ? "ບໍ່ແມ່ນ" : "ແມ່ນ"}
							</span>
						</li>
						{param?.data?.bornAbroad === "" ?
							<>
								<li className="list-group-item">
									ແຂວງ:{" "}
									<span className="float-right">
										{param?.data?.birthAddress?.province}
									</span>
								</li>
								<li className="list-group-item">
									ເມືອງ:{" "}
									<span className="float-right">
										{param?.data?.birthAddress?.district}
									</span>
								</li>
								<li className="list-group-item">
									ບ້ານ:{" "}
									<span className="float-right">
										{param?.data?.birthAddress?.village}
									</span>
								</li>
							</>
							:
							<li className="list-group-item">
								ແຂວງ, ເມືອງ, ບ້ານ:
								<span className="float-right">
									{param?.data?.bornAbroad}
								</span>
							</li>
						}
					</ul>
					<ul className="list-group list-group-flush">
						{studentType !== "IN_PLAN" ? (
							<>
								<li
									className="list-group-item bg-active"
									style={{ color: "#fff", fontWeight: "bold" }}
								>
									<FontAwesomeIcon icon={faCaretDown} />{" "}
									ສຳລັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
								</li>
								<li className="list-group-item">
									ຈົບຈາກແຂວງ:{" "}
									<span className="float-right">
										{param?.data?.graduateFromProvince}
									</span>
								</li>
								<li className="list-group-item">
									ຈົບຈາກໂຮງຮຽນ:{" "}
									<span className="float-right">
										{param?.data?.graduateFromSchool}
									</span>
								</li>
								<li className="list-group-item">
									ສົກສຶກສາ:{" "}
									<span className="float-right">{param?.data?.semesterYear}</span>
								</li>
							</>
						) : (
							<>
								<li
									className="list-group-item bg-active"
									style={{ color: "#fff", fontWeight: "bold" }}
								>
									<FontAwesomeIcon icon={faCaretDown} /> ສໍາຫຼັບນັກສຶກສາ/ພະນັກງານ
									ທຶນແບ່ງປັນ
								</li>
								<li className="list-group-item">
									ຈົບ​ຈາກແຂວງ:{" "}
									<span className="float-right">
										{param?.data?.graduateFromProvince}
									</span>
								</li>
								<li className="list-group-item">
									ຈົບຈາກໂຮງຮຽນ:{" "}
									<span className="float-right">
										{param?.data?.graduateFromSchool}
									</span>
								</li>
								<li className="list-group-item">
									ສົກສຶກສາ:{" "}
									<span className="float-right">{param?.data?.semesterYear}</span>
								</li>
							</>
						)}
						{param?.data?.educationLevel === "BACHELOR_CONTINUING" ? (
							<>
								<li className="list-group-item">
									ຈົບສາຂາວິຊາ:{" "}
									<span className="float-right">
										{param?.data?.graduatedMajor}
									</span>
								</li>
							</>
						) :
							(<></>)
						}
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
						</li>
						<li className="list-group-item">
							ຊື່ ແລະ ນາມສະກຸນພໍ່:{" "}
							<span className="float-right">
								{param?.data?.fatherNameAndSurname}
							</span>
						</li>
						<li className="list-group-item">
							ຊື່ ແລະ ນາມສະກຸນແມ່:{" "}
							<span className="float-right">
								{param?.data?.motherNameAndSurname}
							</span>
						</li>
						<li className="list-group-item">
							ເບີໂທລະສັບພໍ່/ແມ່:{" "}
							<span className="float-right">
								{param?.data?.parentTelephone}
							</span>
						</li>
						<li className="list-group-item">
							ແຂວງ:{" "}
							<span className="float-right">
								{param?.data?.parentAddress?.province}
							</span>
						</li>
						<li className="list-group-item">
							ເມືອງ:{" "}
							<span className="float-right">
								{param?.data?.parentAddress?.district}
							</span>
						</li>
						<li className="list-group-item">
							ບ້ານ:{" "}
							<span className="float-right">
								{param?.data?.parentAddress?.village}
							</span>
						</li>
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> ​ກໍ​ລະ​ນີ​ສຸກ​ເສີນ​ຕິດ​ຕໍ່
						</li>
						<li className="list-group-item">
							ພົວ​ພັນ​ທ່ານ:{" "}
							<span className="float-right">{param?.data?.contactName}</span>
						</li>
						<li className="list-group-item">
							ເບີ​ໂທ​ຕິດ​ຕໍ່:{" "}
							<span className="float-right">{param?.data?.emergencyPhone}</span>
						</li>
						<li className="list-group-item">
							ອ​າ​ໄສ​ຢູ່​ຮ່ວມ​ກັນ:{" "}
							<span className="float-right">
								{param?.data?.stayTogether == true ? "ຢູ່" : "ບໍ່ຢູ່"}
							</span>
						</li>
						{param?.data?.stayTogether === false ? (
							<>
								<li className="list-group-item">
									ແຂວງ:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.province}
									</span>
								</li>
								<li className="list-group-item">
									ເມືອງ:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.district}
									</span>
								</li>
								<li className="list-group-item">
									ບ້ານ:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.village}
									</span>
								</li>
							</>
						) : (<></>)
						}

					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> ອັບໂຫຼດເອກະສານຄັດຕິດ
						</li>
						<li className="list-group-item">
							ອັບໂຫຼດໄຟລຮູບ ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ:{" "}
							<span className="float-right">
								{param?.data?.idCardOrFamilyBookUrl?.url}
							</span>
						</li>
						<li className="list-group-item">
							ອັບໂຫຼດໄຟລຮູບ ໃບປະກາດ{" "}
							<span className="float-right">
								{param?.data?.certificateUrl?.url}
							</span>
						</li>
						<li className="list-group-item">
							ອັບໂຫຼດໄຟລຮູບ ບັດເຂົ້າຫ້ອງເສັງ:{" "}
							<span className="float-right">
								{param?.data?.testCardUrl?.url}
							</span>
						</li>
					</ul>
					<hr />
					<div className="button-confirm">
						<center>
							<Button
								type="submit"
								className="buttonoutline "
								onClick={() => _handleShowAddConfirmModalClose()}
							>
								ຍົກເລີກ
							</Button>
							<Button
								type="submit"
								className="btn btn-default bg-active button-apply-confirm marign-button-apply-right"
								onClick={() => confirmRegistration()}
							>
								ຢືນຢັນ
							</Button>
						</center>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={show} onHide={() => setShow(false)} centered>
				<Modal.Body>
					<Col
						className="text-center"
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<div
							className="textfont-size72 text-bold"
							style={{ color: "#00A1DE" }}
						>
							ສໍາເລັດ
						</div>
						<FontAwesomeIcon
							icon={faCheckCircle}
							size="9x"
							style={{ color: "#00A1DE" }}
						/>
						<div
							className="textfont-size19 text-bold"
							style={{ marginTop: 40 }}
						>
							ປັນທືກເປັນທີ່ຮຽບຮ້ອຍ
						</div>
					</Col>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default RegistrationConfirm;
