import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCheckCircle,
  faTimesCircle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Form,
  Button,
  Modal,
  ProgressBar,
  Spinner,
  Row,
  FormControl,
} from "react-bootstrap";
import RegistrationConfirm from "./RegistrationConfirm";
import useReactRouter from "use-react-router";
import CustomButton from "../../common/CustomButton";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { v4 as uuidv4 } from "uuid";
import { USER_KEY, PRIMARY_COLOR } from "../../consts/index";
import { formatDateYYMMDD, formatDateMMDDYYYY, formatDateDash } from "../../common/super";
import Loading from "../../common/Loading";
import { Formik } from "formik";
import {
  SLMS_FACULTIES,
  SLMS_DEPARTMENTS,
  CREATE_STUDENT,
  UPDATE_STUDENT,
  SLMS_MAJORS,
} from "../../apollo/registrationstudent";
import { PRE_SIGNED_URL } from "../../apollo/registrationstudent";
import { LAO_ADDRESS } from "../../consts/Address";
import { STUDENTS, TRIBES } from "../../apollo/students";

import _ from "lodash";
import { COUNTRIES_LIST } from "../../consts/Countries";

export default function RegistrationStudent() {
  const { history } = useReactRouter();
  const [getCode, setGetCode] = useState();
  const { studentType, code } = history.location.state;
  const [student, setStudent] = useState({});
  const [assessmentYear, setAssessmentYear] = useState("");
  const [phoneHome, setPhoneHome] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [parentTelephone, setParentTelephone] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [fileUploadProgress1, setFileUploadProgress1] = useState(0);
  const [fileUploadProgress2, setFileUploadProgress2] = useState(0);
  const [fileUploadProgress3, setFileUploadProgress3] = useState(0);
  /* <---------- useState faculty ----------> */
  const [selectFacultyIndex, setSelectFacultyIndex] = useState(-1);

  /* <-------- useState Department ---------> */
  const [departmentName, setDepartmentName] = useState();
  const [majorName, setMajorName] = useState();

  /* <-------- useState District ---------> */
  const [selectProvinceIndex, setSelectProvinceIndex] = useState();
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState();
  const [selectParentProvinceIndex, setSelectParentProvinceIndex] = useState();
  const [selectEmergencyProvinceIndex, setSelectEmergencyProvinceIndex] = useState();

  /* <--------  file upload ---------> */
  const [fileUpload, setFileUpload] = useState(null);
  const [idCardOrFamilyBookUrl, setIdCardOrFamilyBookUrl] = useState(null);
  const [idCardOrFamilyBook, setIdCardOrFamilyBook] = useState(null);
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [certificate, setCertificate] = useState(null);
  // const [scorecardUrl, setScorecardUrl] = useState(null)
  // const [scorecard, setScorecard] = useState(null)
  const [testCardUrl, setTestCardUrl] = useState(null);
  const [testCard, setTestCard] = useState(null);

  /* <--------  general State ---------> */
  const [graduatedYear, setGraduatedYear] = useState([]);
  const [formParam, setFormParam] = useState({});
  const [show, setShow] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const [click, setClick] = useState("");
  const [type, setType] = useState("");
  const [errorInput, setErrorInput] = useState({});
  const [tribeId, setTribeId] = useState();
  const [renderCode, setRenderCode] = useState();
  const [scholarship, setScholarship] = useState();
  const [dataLocalStorages, setDataLocalStorages] = useState({});
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
  const [stayTogether, setStayTogether] = useState(false);
  
  /* <--------- apollo -------> */
  const [loadPreSign, { data: dataPreSign }] = useLazyQuery(PRE_SIGNED_URL);
  const [loadFaculties, { data: facultyApollo }] = useLazyQuery(SLMS_FACULTIES);
  const [createStudent] = useMutation(CREATE_STUDENT);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [loadDataDepartmentPrice, { data: dataDepartmentPrice }] =
    useLazyQuery(SLMS_DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(SLMS_MAJORS);
  const [loadDataStudent, { data, loading }] = useLazyQuery(STUDENTS);

  const _onLogout = () => {
    if (student?.statusStep !== "REJECTED") {
      history.push(`/`);
      localStorage.removeItem(USER_KEY);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    const _renderCode = _.split(code, "/");
    setRenderCode(_renderCode[0]);
    setGetCode(getUserDataFromLCStorage()?.code);
    setDataLocalStorages(getUserDataFromLCStorage());
  }, []);

  useEffect(() => {
    let year = [];
    let assessmentYearNow;
    const NOW = new Date().getFullYear();
    const checkMonth = new Date().getMonth() + 1;
    if (
      checkMonth === 8 ||
      checkMonth === 9 ||
      checkMonth === 10 ||
      checkMonth === 11 ||
      checkMonth === 12
    ) {
      assessmentYearNow = NOW + " - " + (NOW + 1);
      for (var i = NOW; i >= 1995; i--) {
        year.push(i + " - " + (parseInt(i) + 1));
      }
    } else {
      assessmentYearNow = NOW - 1 + " - " + NOW;
      for (var i = NOW; i >= 1995; i--) {
        year.push(i - 1 + " - " + parseInt(i));
      }
    }
    setGraduatedYear(year);
    // setAssessmentYear(assessmentYearNow);
    // setGraduatedYear(["2023 - 2024"]);
    setAssessmentYear('2024 - 2025');
  }, []);

  useEffect(() => {
    if (!renderCode || renderCode === "") return;
    loadDataStudent({
      variables: {
        where: { code: renderCode, assessmentYear: assessmentYear },
        orderBy: "createdAt_DESC",
      },
    });
  }, [renderCode]);
  const departmentPricesData = dataDepartmentPrice?.slmsDepartments;
  const { data: dataTribe } = useQuery(TRIBES);
  
  useEffect(() => {
    loadFaculties({
      variables: {
        where: {
          assessmentYear: assessmentYear,
        },
      },
    });
    if (!data || data?.students?.data?.length === 0) return;
    setStudent(data?.students?.data[0]);
    const _renderCode = _.split(getCode, "/");
    setRenderCode(_renderCode[0]);
    setIsChecked(data?.students?.data[0]?.bornAbroad === "" ? false : true);
    setStayTogether(data?.students?.data[0]?.stayTogether === false ? false : true);
  }, [data, student]);

  useEffect(() => {
    if (!_.isEmpty(student)) {
      setIdCardOrFamilyBook(student?.idCardOrFamilyBookUrl?.url);
      setCertificate(student?.certificateUrl?.url);
      // setScorecard(student?.scorecardUrl?.url)
      setTestCard(student?.testCardUrl?.url);
      setIdCardOrFamilyBookUrl(student?.idCardOrFamilyBookUrl?.url);
      setCertificateUrl(student?.certificateUrl?.url);
      // setScorecardUrl(student?.scorecardUrl?.url)
      setTestCardUrl(student?.testCardUrl?.url);
      setPhoneHome(student?.phoneHome);
      setPhoneMobile(student?.phoneMobile);
      setParentTelephone(student?.parentTelephone);
      setEmergencyPhone(student?.emergencyPhone);
      _setIsSave();
    }
  }, [student]);

  useEffect(async () => {
    if (student?.faculty?.id) {
      _selectFacultyEffect(
        student?.faculty?.id || dataLocalStorages?.faculty?.id
      );
    }
    if (dataLocalStorages?.faculty?.id) {
      await loadDataDepartmentPrice({
        variables: {
          where: {
            faculty: dataLocalStorages?.faculty?.id,
          },
        },
      });
    }
    if (student?.department?.id) {
      _selectDepartmentEffect(
        student?.department?.id || dataLocalStorages?.department?.id
      );
    }
    if (dataLocalStorages?.department?.id) {
      await loadMajors({
        variables: {
          where: {
            department: dataLocalStorages?.department?.id,
          },
        },
      });
    }
  }, [student?.faculty?.id, student?.department?.id, dataLocalStorages]);

  // select faculty
  const _selectFacalty = (e) => {
    const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
      id: e.target.value,
    });

    if (e?.target?.value) {
      loadDataDepartmentPrice({
        variables: {
          where: {
            faculty: e.target.value,
          },
        },
      });
    }
    setSelectFacultyIndex(facultyIndex);
  };

  const _selectFacultyEffect = async (id) => {
    loadDataDepartmentPrice({
      variables: {
        where: {
          faculty: id,
        },
      },
    });
  };
  const _selectDepartmentEffect = async (id) => {
    loadMajors({
      variables: {
        where: {
          department: id,
        },
      },
    });
  };

  // select department
  const _selectDepartment = (e) => {
    const departmentIndex = _.findIndex(departmentPricesData, {
      id: e.target.value,
    });
    if (e?.target?.value) {
      loadMajors({
        variables: {
          where: {
            department: e.target.value,
          },
        },
      });
    }
    setDepartmentName(departmentPricesData[departmentIndex]?.name);
  };
  const _selectMajor = (e) => {
    const majorIndex = _.findIndex(majorData?.slmsMajors, {
      id: e.target.value,
    });
    setMajorName(majorData?.slmsMajors[majorIndex]?.name);
  };

  // select province
  const _selectProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectProvinceIndex(_provinceIndex);
  };

  const _selectBirthProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
  };

  const _selectParentProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectParentProvinceIndex(_provinceIndex);
  };

  // select District
  const _selectDistrict = (e) => {
    const _districts = LAO_ADDRESS[selectProvinceIndex].district_list.filter(
      (_district) => _district.district === e.target.value
    );
  };

  const _selectEmergencyProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectEmergencyProvinceIndex(_provinceIndex);
  };

  // useEffect province from database
  useEffect(() => {
    const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
      id: student?.faculty?.id || dataLocalStorages?.faculty?.id,
    });
    setSelectFacultyIndex(facultyIndex);
    setDepartmentName(dataLocalStorages?.department?.name);
    setMajorName(dataLocalStorages?.major?.name);
  }, [student, data, student, facultyApollo]);

  useEffect(() => {
    const _provinceBirthIndex = _.findIndex(LAO_ADDRESS, {
      province_name:
        student?.birthAddress?.province ||
        dataLocalStorages?.birthAddress?.province,
    });
    setSelectBirthProvinceIndex(_provinceBirthIndex);

    const _provinceParentIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.parentAddress?.province,
    });
    setSelectParentProvinceIndex(_provinceParentIndex);

    const _provinceEmergencyIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.emergencyAddress?.province,
    });
    setSelectEmergencyProvinceIndex(_provinceEmergencyIndex);

    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.address?.province,
    });
    setSelectProvinceIndex(_provinceIndex);
  }, [student]);

  useEffect(() => {
    _setIsSave();
  }, [idCardOrFamilyBookUrl, testCardUrl, certificateUrl]);

  useEffect(() => {
    if (dataPreSign?.preSignedUrl) {
      fileGetResign(dataPreSign?.preSignedUrl);
    } else {
      setIsLoading(false);
    }
  }, [dataPreSign]);

  const fileGetResign = async (data) => {
    try {
      await axios({
        method: "put",
        url: data.url,
        data: fileUpload,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
        /* ສະແດງເປີເຊັນຂໍ້ມູນທີ່ອັບໂຫລດແລ້ວ  */
        onUploadProgress: function (progressEvent) {
          let percentComplete = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
          if (click === "1") {
            setIsLoading(true);
            setFileUploadProgress1(percentComplete);
          }
          if (click === "2") {
            setIsLoading(true);
            setFileUploadProgress2(percentComplete);
          }
          if (click === "3") {
            setIsLoading(true);
            setFileUploadProgress3(percentComplete);
          }
          if (click === "1" && percentComplete === 100) {
            setIdCardOrFamilyBookUrl(data?.url.split("?")[0]);
            setFileUploadProgress1(0);
            setIsLoading(false);
          }
          if (click === "2" && percentComplete === 100) {
            setCertificateUrl(data?.url.split("?")[0]);
            setFileUploadProgress2(0);
            setIsLoading(false);
          }
          if (click === "3" && percentComplete === 100) {
            setTestCardUrl(data?.url.split("?")[0]);
            setFileUploadProgress3(0);
            setIsLoading(false);
          }
        },
      });
    } catch (error) {
      if (error.response) {
        // get response with a status code not in range 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // no response
        console.log(error.request);
      } else {
        // Something wrong in setting up the request
        console.log("Error", error.message);
      }
      console.log(error.config);
      setIsLoading(false);
    }
  };

  // ເລືອກໄຟລຈາກ ຄອມ
  const _handleChangeFile = async (acceptedFiles) => {
    try {
      // ໄປຂໍ url ຈາກ s3
      // setIsLoading(true)
      let data =
        uuidv4() + "." + acceptedFiles?.target?.files[0].type.split("/")[1];
      switch (click) {
        case "1":
          setIdCardOrFamilyBook(data);
          break;
        case "2":
          setCertificate(data);
          break;
        case "3":
          setTestCard(data);
      }
      setFileUpload(acceptedFiles?.target?.files[0]);
      loadPreSign({
        variables: {
          name: data,
        },
      });
    } catch (error) {
      setIsLoading(false);
    }
  };
  var arr = [];
  const _setIsSave = () => {
    if (idCardOrFamilyBookUrl && testCardUrl && certificateUrl) {
      setIsSave(false);
    } else {
      setIsSave(true);
    }
  };

  const _selectTribe = (tribe) => {
    setTribeId(dataTribe?.tribes[tribe.target.value]?.id);
  };

  const selectScholarship = (e) => {
    setScholarship(e.target.value);
  };

  const _clickStayTogether = (e) => {
    setStayTogether(!stayTogether);
  };

  const handlePhoneHomeChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    if (rx_live.test(evt.target.value)) {
      setPhoneHome(evt.target.value);
    }
  };
  const handlePhoneMobileChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    if (rx_live.test(evt.target.value)) {
      setPhoneMobile(evt.target.value);
    }
  };
  const handleParentTelephoneChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    if (rx_live.test(evt.target.value)) {
      setParentTelephone(evt.target.value);
    }
  };
  const handleEmergencyPhoneChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    if (rx_live.test(evt.target.value)) {
      setEmergencyPhone(evt.target.value);
    }
  };
  const handleSubmit2 = async (event, values) => {
    event.preventDefault();

    var errors = {};
    if (studentType == "IN_PLAN") {
      if (values.email == "") {
        delete values.email;
      }
      if (values.lastNameL == "") {
        delete values.lastNameL;
      }
      if (values.scholarshipAgreementNo == "") {
        delete values.scholarshipAgreementNo;
      }
      if (values.transferLetterNo === "") {
        delete values.transferLetterNo;
      }
      delete values.outPlanGraduateFromProvince;
      delete values.outPlanGraduateFromSchool;
      delete values.outPlanSemesterYear;

      if (!values.scholarship) {
        errors.scholarship = "Please select scholarship";
      }
      if (!values.graduateFromProvince) {
        errors.graduateFromProvince = "Please input graduate province/state";
      }
      if (!values.graduateFromSchool) {
        errors.graduateFromSchool = "Please input graduate school";
      }
      if (!values.semesterYear) {
        errors.semesterYear = "Please input academic year";
      }
    }
    delete values.examCode;
    if (values.email == "") {
      delete values.email;
    }
    if (values.lastNameL == "") {
      delete values.lastNameL;
    }
    delete values.otherScholarship;
    // delete values.stayTogether;
    delete values.acceptTerms;
    if (studentType == "OUT_PLAN") {
      if (values.email == "") {
        delete values.email;
      }
      if (values.lastNameL == "") {
        delete values.lastNameL;
      }
      delete values.graduateFromProvince;
      delete values.graduateFromSchool;
      delete values.scholarship;
      if (values.scholarshipAgreementNo == "") {
        delete values.scholarshipAgreementNo;
      }
      delete values.semesterYear;
      if (values.transferLetterNo === "") {
        delete values.transferLetterNo;
      }

      if (!values.outPlanGraduateFromProvince) {
        errors.outPlanGraduateFromProvince =
          "Please input graduate province/state";
      }
      if (!values.outPlanGraduateFromSchool) {
        errors.outPlanGraduateFromSchool = "Please input graduate school";
      }
      if (!values.outPlanSemesterYear) {
        errors.outPlanSemesterYear = "Please input academic year";
      }
    }

    if (dataLocalStorages?.educationLevel === "BACHELOR_CONTINUING") {
      if (!values.graduatedMajor) {
        errors.graduatedMajor = "Please input graduated major";
      }
    }

    if (values.stayTogether === false) {
      if (!values.emergencyProvince) {
        errors.emergencyProvince = "Please input emergency province/state";
      }
      if (!values.emergencyDistrict) {
        errors.emergencyDistrict = "Please input emergency district";
      }
      if (!values.emergencyVillage) {
        errors.emergencyVillage = "Please input emergency village";
      }
    }

    if (!values.studentInfo) {
      errors.studentInfo = "Please select student's information";
    }
    if (!values.educationLevel) {
      errors.educationLevel = "Please select level";
    }
    if (!values.firstNameE) {
      errors.firstNameE = "Please input given name";
    }
    if (!values.lastNameE) {
      errors.lastNameE = "Please input family name";
    }
    if (!values.nationality) {
      errors.nationality = "Please select nationality";
    }
    if (!values.tribe) {
      errors.tribe = "Please select tribe";
    }
    if (!values.religion) {
      errors.religion = "Please select religion";
    }
    // if(!phoneHome) {
    //   errors.phoneHome = "Please input your home phone"
    // }
    if (!phoneMobile) {
      errors.phoneMobile = "Please input your mobile phone";
    }
    if (!values.address) {
      errors.address = "Please input current province/state";
    }
    if (!values.district) {
      errors.district = "Please input current district";
    }
    if (!values.village) {
      errors.village = "Please input current village";
    }
    if (isChecked === true) {
      if (!values.birthCountry) {
        errors.birthCountry = "Please input birth country";
      }
    }
    if (!values.birthProvince) {
      errors.birthProvince = "Please input birth province/state";
    }
    if (!values.birthDistrict) {
      errors.birthDistrict = "Please input birth district";
    }
    if (!values.birthVillage) {
      errors.birthVillage = "Please input birth village";
    }
    if (!values.fatherNameAndSurname) {
      errors.fatherNameAndSurname = "Please input father name and surname";
    }
    if (!values.motherNameAndSurname) {
      errors.motherNameAndSurname = "Please input mother name and surname";
    }
    if (!parentTelephone) {
      errors.parentTelephone = "Please input parents mobile phone";
    }
    if (!values.parentProvince) {
      errors.parentProvince = "Please input parent province/state";
    }
    if (!values.parentDistrict) {
      errors.parentDistrict = "Please input parents district";
    }
    if (!values.parentVillage) {
      errors.parentVillage = "Please input parent village";
    }
    if (!values.contactName) {
      errors.contactName = "Please input contact name";
    }
    if (!emergencyPhone) {
      errors.emergencyPhone = "Please input emergency phone";
    }
    setErrorInput(errors);

    let address = {
      province: values.address,
      district: values.district,
      village: values.village,
    };
    values = {
      ...values,
      address: address,
    };
    delete values.village;
    delete values.district;

    let birthAddress = {};
    if (isChecked === true) {
      values = { ...values, bornAbroad: "true" }
      birthAddress = {
        country: values.birthCountry,
        province: values.birthProvince,
        district: values.birthDistrict,
        village: values.birthVillage,
      };
    }
    if (isChecked === false) {
      delete values.bornAbroad;
      values = { ...values, bornAbroad: "" };
      birthAddress = {
        province: values.birthProvince,
        district: values.birthDistrict,
        village: values.birthVillage,
      };
    }
    values = {
      ...values,
      birthAddress: birthAddress,
    };
    delete values.birthCountry;
    delete values.birthProvince;
    delete values.birthVillage;
    delete values.birthDistrict;

    let emergencyAddress = {};
    if (stayTogether === false) {
      emergencyAddress = {
        province: values.emergencyProvince,
        district: values.emergencyDistrict,
        village: values.emergencyVillage,
      };
      values = {
        ...values,
        emergencyAddress: emergencyAddress,
      };

    }
    delete values.emergencyProvince;
    delete values.emergencyDistrict;
    delete values.emergencyVillage;

    let parentAddress = {
      province: values.parentProvince,
      district: values.parentDistrict,
      village: values.parentVillage,
    };
    values = {
      ...values,
      parentAddress: parentAddress,
    };
    delete values.parentProvince;
    delete values.parentVillage;
    delete values.parentDistrict;

    if (dataLocalStorages?.educationLevel !== "BACHELOR_CONTINUING") {
      delete values.graduatedMajor;
    }

    let data = {
      ...values,
      yearLevel: "1",
      code: renderCode,
      examCode: dataLocalStorages?.id,
      faculty: facultyApollo?.slmsFaculties[selectFacultyIndex]?.id,
      department: values.department,
      major: values.major,
      facultyname: facultyApollo?.slmsFaculties[selectFacultyIndex]?.name,
      departmentname: departmentName,
      majorname: majorName,
      tribe: values.tribe,
      idCardOrFamilyBookUrl: { url: idCardOrFamilyBook },
      certificateUrl: { url: certificate },
      testCardUrl: { url: testCard },
      // scorecardUrl: { url: scorecardUrl },
      phoneHome: phoneHome,
      phoneMobile: phoneMobile,
      parentTelephone: parentTelephone,
      emergencyPhone: emergencyPhone,
      studentType: studentType,
      statusStep: "SEND_FORM",
      // statusStepUpdate: student?.statusStep,
      graduateFromProvince: values.outPlanGraduateFromProvince
        ? values.outPlanGraduateFromProvince
        : values.graduateFromProvince,
      graduateFromSchool: values.outPlanGraduateFromSchool
        ? values.outPlanGraduateFromSchool
        : values.graduateFromSchool,
      semesterYear: values.outPlanSemesterYear
        ? values.outPlanSemesterYear
        : values.semesterYear,
      id: student?.id,
    };
    delete data.outPlanGraduateFromProvince;
    delete data.outPlanGraduateFromSchool;
    delete data.outPlanSemesterYear;
    delete data.birthDistrict;
    delete data.birthVillage;
    delete data.district;
    delete data.village;
    if (student) {
      data.id = student.id;
    }
    let paramQL = {
      data,
    };
    // return console.log("data:", paramQL);
    setFormParam(paramQL);
    const checkData = _.isEmpty(errors);
    if (!checkData) {
      setShowDanger(true);
      setTimeout(() => {
        setShowDanger(false);
      }, 2500);
      return;
    }
    // return console.log("data::", data)
    if (type == "save") {
      try {
        if (!_.isEmpty(student)) {
          delete data.facultyname;
          delete data.departmentname;
          delete data.majorname;
          // delete data.statusStepUpdate;
          delete data.statusStep;
          const updateData = await updateStudent({
            variables: {
              where: { id: student?.id },
              data: data,
            },
          });
          if (updateData?.data?.updateStudent?.id) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              window.location.reload();
            }, 1000);
          }
        }
        if (_.isEmpty(student)) {
          delete data.facultyname;
          delete data.departmentname;
          delete data.majorname;
          delete data.statusStep;
          const createNewStudent = await createStudent({ variables: paramQL });
          if (createNewStudent?.data?.createStudent?.id) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              window.location.reload();
            }, 1000);
          }
        }
      } catch (err) { }
    } else if (type == "verify") {
      _handleShowAddConfirmModalShow();
    }
  };

  if (loading) return <Loading />;
  return (
    <div className="container mt-5 mb-5">
      <div className="col-sm-12 card p-4" style={{ color: "#707070" }}>
        {/* {studentData && */}
        {
          <Formik
            enableReinitialize={true}
            initialValues={{
              examCode: dataLocalStorages?.id ?? "",
              faculty: student?.faculty?.id ?? dataLocalStorages?.faculty?.id,
              department:
                student?.department?.id ?? dataLocalStorages?.department?.id,
              major: student?.major?.id ?? dataLocalStorages?.major?.id,
              studentNumber: getCode,
              studentInfo: student?.studentInfo ?? "",
              educationLevel: student?.educationLevel ?? dataLocalStorages?.educationLevel,
              firstNameL: student?.firstNameL ?? dataLocalStorages?.firstNameL,
              lastNameL: student?.lastNameL ?? dataLocalStorages?.lastNameL,
              firstNameE: student?.firstNameE ?? dataLocalStorages?.firstNameE,
              lastNameE: student?.lastNameE ?? dataLocalStorages?.lastNameE,
              birthday: student?.birthday ?? dataLocalStorages?.birthday,
              dateTransferLetterNo:
                formatDateYYMMDD(student?.dateTransferLetterNo) ?? "",
              dateScholarshipAgreementNo:
                formatDateYYMMDD(student?.dateScholarshipAgreementNo) ?? "",
              gender: student?.gender ?? dataLocalStorages?.gender ?? "MALE",
              healthStatus: student?.healthStatus ?? "GOOD",
              health: student?.health ?? "",
              maritualStatus: student?.maritualStatus ?? "SINGLE",
              nationality: student?.nationality ?? "",
              tribe: student?.tribe?.id ?? "",
              religion: student?.religion ?? "",
              // phoneHome: student?.phoneHome ?? "",
              // phoneMobile:
              //   student?.phoneMobile ?? dataLocalStorages?.phoneMobile,
              email: student?.email ?? "",
              address: student?.address?.province ?? "",
              district: student?.address?.district ?? "",
              village: student?.address?.village ?? "",
              // bornAbroad: student?.bornAbroad ?? "",
              birthCountry: student?.birthAddress?.country ?? "",
              birthProvince:
                student?.birthAddress?.province ??
                dataLocalStorages?.birthAddress?.province,
              birthDistrict:
                student?.birthAddress?.district ??
                dataLocalStorages?.birthAddress?.district,
              birthVillage:
                student?.birthAddress?.village ??
                dataLocalStorages?.birthAddress?.village,
              parentProvince: student?.parentAddress?.province ?? "",
              parentDistrict: student?.parentAddress?.district ?? "",
              parentVillage: student?.parentAddress?.village ?? "",
              scholarship: student?.scholarship ?? "",
              otherScholarship: student?.otherScholarship ?? "",
              graduateFromProvince: student?.graduateFromProvince ?? "",
              graduateFromSchool: student?.graduateFromSchool ?? "",
              semesterYear: student?.semesterYear ?? "2023 - 2024",
              outPlanGraduateFromProvince: student?.graduateFromProvince ?? "",
              outPlanGraduateFromSchool: student?.graduateFromSchool ?? "",
              outPlanSemesterYear: student?.semesterYear ?? "2023 - 2024",
              graduatedMajor: student?.graduatedMajor ?? "",
              contactName:
                student?.contactName ?? dataLocalStorages?.contactName,
              // emergencyPhone:
              //   student?.emergencyPhone ?? dataLocalStorages?.emergencyPhone,
              stayTogether: student?.stayTogether ?? false,
              acceptTerms: student?.acceptTerms ?? false,
              emergencyProvince: student?.emergencyAddress?.province,
              emergencyDistrict: student?.emergencyAddress?.district,
              emergencyVillage: student?.emergencyAddress?.village,
              dormitory: student?.dormitory ?? "YES",
              fatherNameAndSurname: student?.fatherNameAndSurname ?? "",
              motherNameAndSurname: student?.motherNameAndSurname ?? "",
              // parentTelephone: student?.parentTelephone ?? "",
              transferLetterNo: student?.transferLetterNo ?? "",
              scholarshipAgreementNo: student?.scholarshipAgreementNo ?? "",
            }}
            validate={(values) => { }}
          >
            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={(event) => handleSubmit2(event, values)}>
                <div className="card_registrationStudents">
                  <div className="card-body card_registerstrationStudents_body">
                    {/* <span className="float-right textfont-size18">
                      <a
                        href="https://nuol.s3.ap-southeast-1.amazonaws.com/dev/khumueUP2022-2023-LAO.pdf"
                        target="_blank"
                      >
                        <u>ຄູ່ມືແນະນຳ</u>
                      </a>
                    </span> */}
                    {student?.statusStep == "REJECTED" && student?.comment ? (
                      <div className="alert alert-danger mt-5">
                        {student?.comment}{" "}
                        {student?.rejectBy ? `(${student?.rejectBy})` : ""}
                      </div>
                    ) : (
                      ""
                    )}
                    <h6 className="mt-5 textForm">
                      ແບບ​ຟອມ​ຂໍ້​ມູນ​ລົງ​ທະ​ບຽນ
                    </h6>
                    <div className="card bg-register">
                      <b>ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</b>
                    </div>

                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ລະຫັດນັກສຶກສາ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="ກະ​ລຸ​ນາ​ລະຫັດນັກສືກສາ"
                          name="studentNumber"
                          value={values.studentNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຄະ​ນ​ະ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="faculty"
                          isInvalid={errors.faculty}
                          value={values.faculty}
                          onChange={(e) => {
                            handleChange(e);
                            _selectFacalty(e);
                          }}
                          disabled
                        >
                          <option disabled={false} value="">
                            ---ເລືອກ---
                          </option>
                          {facultyApollo?.slmsFaculties.map((x, index) => (
                            <option key={"faculty" + index} value={x.id}>
                              {x.name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ພາກວິຊາ
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="department"
                          isInvalid={errors.department}
                          value={values.department}
                          onChange={(e) => {
                            handleChange(e);
                            _selectDepartment(e);
                          }}
                          disabled
                        >
                          <option value="">---ເລືອກ---</option>
                          {departmentPricesData &&
                            departmentPricesData?.map((x, index) => (
                              <option key={"department" + index} value={x.id}>
                                {x.name}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ສາ​ຂາ​ວິ​ຊາ
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="major"
                          isInvalid={errors.major}
                          value={values.major}
                          onChange={(e) => {
                            handleChange(e);
                            _selectMajor(e);
                          }}
                          disabled
                        >
                          <option value="">---ເລືອກ---</option>
                          {majorData?.slmsMajors &&
                            majorData?.slmsMajors?.map((x, index) => (
                              <option key={index} value={x.id}>
                                {x.name}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold" name="">
                          ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="studentInfo"
                          value={values.studentInfo}
                          // disabled
                          isInvalid={errorInput && errorInput?.studentInfo}
                          onChange={handleChange}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="EMPLOYEE">
                            ເປັນພະນັກງານ/ນັກສຶກສາມາຮຽນຕໍ່
                          </option>
                          <option value="ORDINARY_STUDENT">
                            ນັກຮຽນຈົບ ມ ປາຍມາຮຽນຕໍ່
                          </option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ລະ​ດັບ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.educationLevel}
                          name="educationLevel"
                          disabled
                          isInvalid={errorInput && errorInput?.educationLevel}
                          onChange={handleChange}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="DEPLOMA">ອະນຸປະລິນຍາ</option>
                          <option value="BACHELOR_DEGREE">ປະລິນຍາຕີ</option>
                          <option value="BACHELOR_CONTINUING">
                            ຕໍ່ເນື່ອງປະລິນຍາຕີ
                          </option>
                        </Form.Control>
                      </div>
                    </div>
                    <div className="card bg-register mt-5">
                      <b>ຂໍ້ມູນທົ່ວໄປ</b>
                    </div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-xs-12 col-md-2">
                        <label className="text-bold">
                          ເລືອກເພດ <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ຊາຍ"
                            type="radio"
                            value="MALE"
                            id="gender-radio"
                            name="gender"
                            disabled
                            onChange={handleChange}
                            checked={values.gender == "MALE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ຍິງ"
                            type="radio"
                            value="FEMALE"
                            id="gender-radio"
                            name="gender"
                            disabled
                            onChange={handleChange}
                            checked={values.gender == "FEMALE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ພຣະ"
                            type="radio"
                            value="MONK"
                            id="gender-radio"
                            name="gender"
                            disabled
                            onChange={handleChange}
                            checked={values.gender == "MONK" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ສໍາມະເນນ"
                            type="radio"
                            value="NOVICE"
                            id="gender-radio"
                            name="gender"
                            disabled
                            onChange={handleChange}
                            checked={values.gender == "NOVICE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.firstNameL}
                          maxLength="20"
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່​ແທ້​ຂອງ​ທ່ານ"
                          name="firstNameL"
                          disabled={
                            dataLocalStorages?.firstNameL ? true : false
                          }
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.firstNameL}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ນາມສະກຸນ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.lastNameL}
                          maxLength="20"
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                          name="lastNameL"
                          disabled={dataLocalStorages?.lastNameL ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.lastNameL}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ (ພາສາອັງກິດ){" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.firstNameE}
                          maxLength="20"
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນຊື່​ແທ້​ເປັນ​ພາ​ສາ​ອັງ​ກິດ"
                          name="firstNameE"
                          disabled={
                            dataLocalStorages?.firstNameE ? true : false
                          }
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.firstNameE}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ນາມສະກຸນ (ພາສາອັງກິດ){" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.lastNameE}
                          maxLength="20"
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                          name="lastNameE"
                          disabled={dataLocalStorages?.lastNameE ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.lastNameE}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ວັນ​ເດືອນ​ປີ​ເກີດ{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formatDateDash(values.birthday)}
                          placeholder="12/02/2000"
                          name="birthday"
                          onChange={handleChange}
                          disabled
                          isInvalid={errors.birthday}
                        />
                        {/* disabled */}
                      </div>
                      <div className="col-md-6 mt-3" />
                      <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          ສຸຂະພາບ <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ສົມບຸນ"
                            type="radio"
                            value="GOOD"
                            id="healthStatus-radio"
                            name="healthStatus"
                            onChange={handleChange}
                            checked={
                              values.healthStatus === "GOOD" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ພິການ"
                            type="radio"
                            value="BAD"
                            id="healthStatus-radio"
                            name="healthStatus"
                            onChange={handleChange}
                            checked={
                              values.healthStatus === "BAD" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      {values.healthStatus == "BAD" ? (
                        <div className="col-md-12 mt-3">
                          <label>ພິການປະເພດໃດ</label>
                          <Form.Control
                            type="text"
                            value={values.health}
                            name="health"
                            placeholder="ອະທິບາຍການພິການເຊັ່ນ: ຫູບໍ່ໄດ້ຍີນ, ຕາບອດ"
                            onChange={handleChange}
                            disabled={
                              values.healthStatus === "GOOD" ? true : false
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-12 mt-3" />
                      <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          ສະຖານະ <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ໂສດ"
                            type="radio"
                            value="SINGLE"
                            id="maritualStatus-radio"
                            name="maritualStatus"
                            onChange={handleChange}
                            checked={
                              values.maritualStatus == "SINGLE" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ແຕ່ງງານແລ້ວ"
                            type="radio"
                            id="maritualStatus-radio"
                            value="MARRIAGE"
                            name="maritualStatus"
                            onChange={handleChange}
                            checked={
                              values.maritualStatus == "MARRIAGE" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 mt-3" />
                      <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                            type="radio"
                            value="YES"
                            id="dormitory-radio"
                            name="dormitory"
                            onChange={handleChange}
                            checked={values.dormitory === "YES" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-12 col-md-6 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                            type="radio"
                            id="dormitory-radio"
                            value="NO"
                            name="dormitory"
                            onChange={handleChange}
                            checked={values.dormitory === "NO" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ສັນຊາດ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.nationality}
                          name="nationality"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.nationality}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="ລາວ">ລາວ</option>
                          <option value="ກຳປູເຈຍ">ກຳປູເຈຍ</option>
                          <option value="ໄທ">ໄທ</option>
                          <option value="ຈີນ">ຈີນ</option>
                          <option value="ຍີ່ປຸ່ນ">ຍີ່ປຸ່ນ</option>
                          <option value="ເກົາຫຼີ">ເກົາຫຼີ</option>
                          <option value="ຫວຽດ">ຫວຽດ</option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊົນເຜົ່າ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.tribe}
                          name="tribe"
                          onChange={(e) => {
                            handleChange(e);
                            _selectTribe(e);
                          }}
                          isInvalid={errorInput && errorInput?.tribe}
                        >
                          <option value="">---ເລືອກ---</option>
                          {dataTribe &&
                            dataTribe?.tribes.map((item, index) => {
                              return (
                                <option value={item.id}>
                                  {item?.trib_name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ສາສະໜາ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.religion}
                          name="religion"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.religion}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="ພຸດ">​ສາ​ສ​ະ​ໜາ​ພຸດ</option>
                          <option value="ຜີ">ສາ​ສະ​ໜາຜີ</option>
                          <option value="ຄຣິສະ​ຕຽນ">ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ</option>
                          <option value="ສາ​ສະ​ໜາອິດສະລາມ">
                            ສາ​ສະ​ໜາອິດສະລາມ
                          </option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີໂທເຮືອນ
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          pattern="[+-]?\d+(?:[.,]\d+)?"
                          className="form-control"
                          value={phoneHome}
                          maxLength="15"
                          placeholder="ກະລຸນາ ປ້ອນເບີໂທເຮືອນ"
                          name="phoneHome"
                          // isInvalid={errorInput && errorInput?.phoneHome}
                          onChange={handlePhoneHomeChange}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີໂທລະສັບມືຖື <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          pattern="[+-]?\d+(?:[.,]\d+)?"
                          className="form-control"
                          value={phoneMobile}
                          maxLength="15"
                          placeholder="ກະລຸນາ ປ້ອນເບີໂທລະສັບມືຖື"
                          name="phoneMobile"
                          isInvalid={errorInput && errorInput?.phoneMobile}
                          onChange={handlePhoneMobileChange}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ອີເມວ
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.email}
                          maxLength="100"
                          placeholder="ກະລຸນາປ້ອນອີເມວ"
                          name="email"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="card bg-register mt-5">
                      <b>ທີ່ຢູ່ປະຈຸບັນ</b>
                    </div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ແຂວງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="address"
                          onChange={(e) => {
                            _selectProvince(e);
                            handleChange(e);
                          }}
                          value={values.address}
                          isInvalid={errorInput && errorInput?.address}
                          id="disabledSelect"
                          placeholder="ປະລິນຍາຕີ"
                        >
                          <option value="">---ເລືອກແຂວງ---</option>
                          {LAO_ADDRESS.map((province, index) => (
                            <option
                              key={"province" + index}
                              value={province?.province_name}
                            >
                              {province?.province_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເມືອງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.district}
                          isInvalid={errorInput && errorInput?.district}
                          name="district"
                          onChange={(e) => {
                            _selectDistrict(e);
                            handleChange(e);
                          }}
                        // value={values.district}
                        >
                          <option value="">---ເລືອກເມືອງ---</option>
                          {selectProvinceIndex > -1 &&
                            LAO_ADDRESS[selectProvinceIndex].district_list.map(
                              (district, index) => (
                                <option key={"district" + index}>
                                  {district.district}
                                </option>
                              )
                            )}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ບ້ານ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="village"
                          isInvalid={errorInput && errorInput?.village}
                          placeholder="ກະລຸນາປ້ອນບ້ານ"
                          onChange={handleChange}
                          value={values.village}
                          maxLength="20"
                        />
                      </div>
                    </div>
                    <div className="card bg-register mt-5">
                      <b>ສະຖານທີເກີດ</b>
                    </div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-12 mt-3">
                        <Form.Check
                          type="checkbox"
                          label="ນັກສຶກສາເກີດໃນຕ່າງປະເທດ"
                          inline
                          value={isChecked}
                          checked={isChecked}
                          onClick={() => setIsChecked(!isChecked)}
                        />
                        <span className="text-danger">*</span>
                      </div>
                      {isChecked ? (
                        <>

                          <Row>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ປະເທດ <span className="text-danger">*</span></label>
                                <FormControl
                                  as="select"
                                  className="form-control"
                                  name="birthCountry"
                                  value={values.birthCountry}
                                  onChange={handleChange}
                                  isInvalid={errorInput && errorInput?.birthCountry}
                                >
                                  <option value="">---ເລືອກປະເທດ---</option>
                                  {COUNTRIES_LIST.map((countries, index) => (
                                    <option
                                      key={"countries" + index}
                                      value={countries?.en_short_name}
                                    >
                                      {countries?.en_short_name}
                                    </option>
                                  ))}
                                </FormControl>
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ແຂວງ <span className="text-danger">*</span></label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນແຂວງ"
                                  name="birthProvince"
                                  value={values.birthProvince}
                                  onChange={handleChange}
                                  isInvalid={errorInput && errorInput?.birthProvince}
                                />
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ເມືອງ <span className="text-danger">*</span></label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນເມືອງ"
                                  name="birthDistrict"
                                  value={values.birthDistrict}
                                  onChange={handleChange}
                                  isInvalid={errorInput && errorInput?.birthDistrict}
                                />
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ບ້ານ <span className="text-danger">*</span></label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນບ້ານ"
                                  name="birthVillage"
                                  value={values.birthVillage}
                                  onChange={handleChange}
                                  isInvalid={errorInput && errorInput?.birthVillage}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ແຂວງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="birthProvince"
                              onChange={(e) => {
                                _selectBirthProvince(e);
                                handleChange(e);
                              }}
                              isInvalid={errorInput && errorInput?.birthProvince}
                              value={values.birthProvince}
                            // disabled
                            >
                              <option value="">---ເລືອກແຂວງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province?.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ເມືອງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={values.birthDistrict}
                              name="birthDistrict"
                              isInvalid={
                                errorInput && errorInput?.birthDistrict
                              }
                              onChange={handleChange}
                            // disabled
                            >
                              <option value="">---ເລືອກເມືອງ---</option>
                              {selectBirthProvinceIndex > -1 &&
                                LAO_ADDRESS[
                                  selectBirthProvinceIndex
                                ].district_list.map((district, index) => (
                                  <option
                                    key={"birth-district" + index}
                                    value={district.district}
                                  >
                                    {district.district}
                                  </option>
                                ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ບ້ານ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="birthVillage"
                              value={values.birthVillage}
                              maxLength="20"
                              isInvalid={errorInput && errorInput?.birthVillage}
                              placeholder="ກະລຸນາປ້ອນບ້ານ"
                              onChange={handleChange}
                            // disabled
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {studentType !== "IN_PLAN" ? (
                      <div>
                        <div className="card bg-register mt-5">
                          <b>ສໍາລັບນັກສືກສາລະບົບຈ່າຍຄ່າຮຽນ</b>
                        </div>
                        <div className="row margin-resgintrationStudent">
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບຈາກແຂວງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="outPlanGraduateFromProvince"
                              isInvalid={
                                errorInput &&
                                errorInput?.outPlanGraduateFromProvince
                              }
                              onChange={handleChange}
                              value={values.outPlanGraduateFromProvince}
                            >
                              <option value="">---ເລືອກ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province?.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບຈາກໂຮງຮຽນ/ວິທະຍາໄລ {" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ກະລຸນາປ້ອນ"
                              value={values.outPlanGraduateFromSchool}
                              maxLength="40"
                              name="outPlanGraduateFromSchool"
                              isInvalid={
                                errorInput &&
                                errorInput?.outPlanGraduateFromSchool
                              }
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ສົກສືກສາ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="outPlanSemesterYear"
                              isInvalid={
                                errorInput && errorInput?.outPlanSemesterYear
                              }
                              onChange={handleChange}
                              value={values.outPlanSemesterYear}
                            >
                              {/* <option>---ເລືອກສົກສືກສາ---</option> */}
                              {graduatedYear.map((x, index) => {
                                return (
                                  <option value={x} key={index}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="card bg-register mt-5">
                          <b>ສ​ຳລັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານ ທຶນ​ແບ່ງ​ປັນ</b>
                        </div>
                        <div className="row margin-resgintrationStudent">
                          <div className="col-md-12 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ທຶນແບ່ງປັນ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              id="disabledSelect"
                              name="scholarship"
                              value={values.scholarship}
                              isInvalid={errorInput && errorInput?.scholarship}
                              onChange={(e) => {
                                selectScholarship(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກທຶນ---</option>
                              <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເກັ່ງ​ລະ​ດັບ​ຊາດ
                              </option>
                              <option value="NUOL_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເສັງ​ໄດ້​ທຶນ ມ​ຊ
                              </option>
                              <option value="RED_DIPLOMA_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເກັ່ງ​ປ​ະ​ກາ​ສະ​ນິ​ຍະ​ບັດ​ແດງ
                              </option>
                              <option value="NO_ONE_STUDENT_OF_PROVINCIAL">
                                ນັກ​ຮຽນ​ເກັ່ງ​ເສັງ​ໄດ້​ທີ່ 1 ຂອງ​ແຂວງ
                              </option>
                              <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                                ນັກ​ຮຽນ​ຊົນ​ເຜົ່າ​ຂອ​ງ​ແຂວງ
                              </option>
                              <option value="EXCHANGE_STUDENT_SCHOLARSHIP">
                                ທຶນ​ແບ່ງ​ນັກ​ຮຽນ​ສາ​ມັນ
                              </option>
                              <option value="OFFICIAL_STAFF_SCHOLARSHIP">
                                ພະ​ນັກ​ງານ
                              </option>
                              <option value="OTHER_SHOLARSHIP">
                                ທຶນ​ແບ່ງ​ປັນ​ອື່ນໆ
                              </option>
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບ​ຈາກແຂວງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="graduateFromProvince"
                              value={values.graduateFromProvince}
                              isInvalid={
                                errorInput && errorInput?.graduateFromProvince
                              }
                              onChange={handleChange}
                            >
                              <option value="">---ເລືອກແຂວງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບ​ຈາກ​ໂຮງ​ຮຽນ{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ກະລຸນາປ້ອນ"
                              value={values.graduateFromSchool}
                              maxLength="40"
                              name="graduateFromSchool"
                              isInvalid={
                                errorInput && errorInput?.graduateFromSchool
                              }
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ສົກ​ສຶກ​ສາ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="semesterYear"
                              isInvalid={errorInput && errorInput?.semesterYear}
                              onChange={handleChange}
                              value={values.semesterYear}
                            >
                              {/* <option value="">---ເລືອກສົກສືກສາ---</option> */}
                              {graduatedYear.map((x, index) => {
                                return (
                                  <option value={x} key={index}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ເລກທີໃບນຳສົ່ງ
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="transferLetterNo"
                              placeholder="ກະ​ລຸ​ນາ​ ປ້ອນເລກທີໃບນຳສົ່ງ"
                              onChange={handleChange}
                              value={values.transferLetterNo}
                              maxLength="20"
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ລົງ​ວັນ​ທີ່
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={values.dateTransferLetterNo}
                              name="dateTransferLetterNo"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຊ (ປ້ອນສະເພາະເສັງໄດ້ທຶນ
                              ມຊ)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ"
                              name="scholarshipAgreementNo"
                              value={values.scholarshipAgreementNo}
                              maxLength="20"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ລົງ​ວັນ​ທີ່
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="dateScholarshipAgreementNo"
                              onChange={handleChange}
                              value={values.dateScholarshipAgreementNo}
                            />
                          </div>
                          <div className="col-md-12 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ທຶນ​ແບ່ງ​ປັນອື່ນໆ{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="otherScholarship"
                              placeholder="ກະ​ລຸ​ນາ​ປ້ອນທຶນ​ແບ່ງ​ປັນ​ອື່​ນໆ"
                              isInvalid={errors.otherScholarship}
                              onChange={handleChange}
                              value={values.otherScholarship}
                              maxLength="40"
                              disabled={
                                scholarship == ""
                                  ? true
                                  : scholarship == "OTHER_SHOLARSHIP"
                                    ? false
                                    : true
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {dataLocalStorages?.educationLevel === "BACHELOR_CONTINUING" ? (
                      <>
                        <div className="col-md-4 mt-3">
                          <Form.Label fontSize="14" className="text-bold">
                            ຈົບສາຂາວິຊາ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="graduatedMajor"
                            placeholder="ກະລຸນາປ້ອນສາຂາທີ່ຈົບ"
                            isInvalid={
                              errorInput && errorInput?.graduatedMajor
                            }
                            onChange={handleChange}
                            value={values.graduatedMajor}
                          />
                        </div>
                      </>
                    ) :
                      (
                        <>
                        </>
                      )
                    }
                    <div className="card bg-register mt-5">
                      <b>ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ</b>
                    </div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ ແລະ ນາມສະກຸນພໍ່{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ແລະ ນາມສະກຸນພໍ່"
                          name="fatherNameAndSurname"
                          isInvalid={
                            errorInput && errorInput?.fatherNameAndSurname
                          }
                          onChange={handleChange}
                          value={values.fatherNameAndSurname}
                          maxLength="40"
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ ແລະ ນາມສະກຸນແມ່{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ແລະ ນາມສະກຸນແມ່"
                          name="motherNameAndSurname"
                          isInvalid={
                            errorInput && errorInput?.motherNameAndSurname
                          }
                          onChange={handleChange}
                          value={values.motherNameAndSurname}
                          maxLength="40"
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີໂທລະສັບພໍ່/ແມ່{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນເບີໂທລະສັບພໍ່/ແມ່"
                          name="parentTelephone"
                          isInvalid={errorInput && errorInput?.parentTelephone}
                          onChange={handleParentTelephoneChange}
                          value={parentTelephone}
                          maxLength="15"
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ແຂວງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="parentProvince"
                          onChange={(e) => {
                            _selectParentProvince(e);
                            handleChange(e);
                          }}
                          isInvalid={errorInput && errorInput?.parentProvince}
                          value={values.parentProvince}
                        >
                          <option value="">---ເລືອກແຂວງ---</option>
                          {LAO_ADDRESS.map((province, index) => (
                            <option
                              key={"province" + index}
                              value={province?.province_name}
                            >
                              {province?.province_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເມືອງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.parentDistrict}
                          name="parentDistrict"
                          isInvalid={errorInput && errorInput?.parentDistrict}
                          onChange={handleChange}
                        >
                          <option value="">---ເລືອກເມືອງ---</option>
                          {selectParentProvinceIndex > -1 &&
                            LAO_ADDRESS[
                              selectParentProvinceIndex
                            ].district_list.map((district, index) => (
                              <option
                                key={"parent-district" + index}
                                value={district.district}
                              >
                                {district.district}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ບ້ານ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="parentVillage"
                          value={values.parentVillage}
                          maxLength="20"
                          isInvalid={errorInput && errorInput?.parentVillage}
                          placeholder="ກະລຸນາປ້ອນບ້ານ"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="card bg-register mt-5">
                      <b>ກໍລະນີສຸກເສີນຕິດຕໍ່ (ຜູ້​ປົກ​ຄອງ)</b>
                    </div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ພົວ​ພັນ​ທ່ານ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.contactName}
                          maxLength="30"
                          name="contactName"
                          isInvalid={errorInput && errorInput?.contactName}
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່ ແລະ ນາມ​ສະ​ກຸນ"
                          onChange={handleChange}
                        // disabled
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີ​ໂທ​ຕິດ​ຕໍ່ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          value={emergencyPhone}
                          maxLength="15"
                          name="emergencyPhone"
                          isInvalid={errorInput && errorInput?.emergencyPhone}
                          placeholder="​ກະ​ລຸ​ນາ ປ້ອນ​ເບີ​ຕິດ​ຕໍ່"
                          onChange={handleEmergencyPhoneChange}
                        // disabled
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <Form.Group
                          controlId="formBasicCheckbox"
                          style={{ marginTop: 25 }}
                        >
                          <Form.Check
                            type="checkbox"
                            label="ຢູ່ຮ່ວມກັນ"
                            name="stayTogether"
                            value={values.stayTogether}
                            onChange={handleChange}
                            checked={
                              values.stayTogether === true ? true : false
                            }
                            onClick={() => _clickStayTogether()}
                          />
                        </Form.Group>
                      </div>

                      {stayTogether === false ? (
                        <>
                          <Form.Label fontSize="14" className="text-bold">
                            <span className="text-danger">*</span> ໝາຍເຫດ ຫາກບໍ່ໄດ້ຢູ່ຮ່ວມກັນ ໃຫ້ເລືອກທີ່ຢູ່ດ້ານລຸ່ມ
                          </Form.Label>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ແຂວງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="emergencyProvince"
                              onChange={(e) => {
                                _selectEmergencyProvince(e);
                                handleChange(e);
                              }}
                              isInvalid={errorInput && errorInput?.emergencyProvince}
                              value={values.emergencyProvince}
                            >
                              <option value="">---ເລືອກແຂວງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province?.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ເມືອງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={values.emergencyDistrict}
                              name="emergencyDistrict"
                              isInvalid={errorInput && errorInput?.emergencyDistrict}
                              onChange={handleChange}
                            >
                              <option value="">---ເລືອກເມືອງ---</option>
                              {selectEmergencyProvinceIndex > -1 &&
                                LAO_ADDRESS[
                                  selectEmergencyProvinceIndex
                                ].district_list.map((district, index) => (
                                  <option
                                    key={"parent-district" + index}
                                    value={district.district}
                                  >
                                    {district.district}
                                  </option>
                                ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ບ້ານ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="emergencyVillage"
                              value={values.emergencyVillage}
                              maxLength="20"
                              isInvalid={errorInput && errorInput?.emergencyVillage}
                              placeholder="ກະລຸນາປ້ອນບ້ານ"
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      )
                        :
                        (<></>)
                      }
                    </div>
                    <div className="card bg-register mt-5">
                      <b>ອັບໂຫຼດເອກະສານຄັດຕິດ</b>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      ສາມາດອັບໂຫຼດຟາຍຮູບໄດ້ສະເພາະນາມສະກຸນ: .png, .heif, .jpeg,
                      .jpg, .pdf ເທົ່ານັ້ນ
                    </div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ (ພຣະ:
                        ໜັງສືສຸທິ) <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload"
                            onChange={_handleChangeFile}
                            hidden
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onClick={() => setClick("1")}
                          />
                          <label
                            htmlFor="file-upload"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "1" &&
                                fileUploadProgress1 > 0 &&
                                fileUploadProgress1 < 100 ? (
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar
                                    animated
                                    now={fileUploadProgress1}
                                    label={`${fileUploadProgress1}%`}
                                  />
                                </div>
                              ) : (
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              )}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {idCardOrFamilyBookUrl &&
                            fileUploadProgress1 === 0 && (
                              <aside>
                                <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                                {idCardOrFamilyBook}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      setIdCardOrFamilyBook(null);
                                      setIdCardOrFamilyBookUrl(null);
                                    }}
                                    variant={"danger"}
                                  >
                                    ລົບໄຟລ
                                  </Button>
                                </div>
                              </aside>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ໃບປະກາດ{" "}
                        <span className="text-danger">*</span>
                        <br />
                        (ກໍລະນີນັກສຶກສາຕໍ່ເນື່ອງທີ່ບໍ່ມີໃບປະກາດ ໃຫ້ໃຊ້ ໃບຄະແນນ
                        ແທນ)
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload3"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("2")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload3"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "2" &&
                                fileUploadProgress2 > 0 &&
                                fileUploadProgress2 < 100 ? (
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar
                                    animated
                                    now={fileUploadProgress2}
                                    label={`${fileUploadProgress2}%`}
                                  />
                                </div>
                              ) : (
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              )}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {certificateUrl && fileUploadProgress2 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {certificate}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setCertificate(null);
                                    setCertificateUrl(null);
                                  }}
                                  variant={"danger"}
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ໃບເຂົ້າຫ້ອງເສັງຂອງ ມຊ/ໃບໜັງສືນຳສົ່ງ{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload4"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("3")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload4"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "3" &&
                                fileUploadProgress3 > 0 &&
                                fileUploadProgress3 < 100 ? (
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar
                                    animated
                                    now={fileUploadProgress3}
                                    label={`${fileUploadProgress3}%`}
                                  />
                                </div>
                              ) : (
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              )}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {testCardUrl && fileUploadProgress3 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {testCard}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setTestCard(null);
                                    setTestCardUrl(null);
                                  }}
                                  variant={"danger"}
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row fixed-bottom py-3"
                    style={{ backgroundColor: "#fff", marginBottom: 0 }}
                  >
                    <div className="col-xs-6 col-md-4 col-4 text-center">
                      <Button
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                        className="buttonoutline"
                        onClick={() => setShowConfirmLogout(true)}
                      >
                        ຍົກເລີກ
                      </Button>
                    </div>
                    <div className="col-xs-6 col-md-4 col-4 text-center">
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          setType("save");
                          // handleSubmit()
                        }}
                        style={{ backgroundColor: "#1B8900", color: "#fff" }}
                        disabled={isLoading ? true : false}
                      >
                        ບັນທຶກ
                      </Button>
                    </div>
                    <div className="col-xs-6 col-md-4 col-4 text-center">
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                          setType("verify");
                          // handleSubmit();
                        }}
                        disabled={
                          isSave == true ? true : isLoading ? true : false
                        }
                      >
                        ສົ່ງຂໍ້ມູນ <FontAwesomeIcon icon={faPaperPlane} />
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        }
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {isSaving ? (
            <Col
              className="text-center"
              style={{ paddingTop: 40, paddingBottom: 50 }}
            >
              <Spinner animation="border" style={{ color: "#057CAE" }} />
            </Col>
          ) : (
            <Col
              className="text-center"
              style={{ paddingTop: 40, paddingBottom: 50 }}
            >
              <div
                className="textfont-size72 text-bold"
                style={{ color: "#00A1DE" }}
              >
                ສໍາເລັດ
              </div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="9x"
                style={{ color: "#00A1DE" }}
              />
              <div
                className="textfont-size19 text-bold"
                style={{ marginTop: 40 }}
              >
                ບັນທຶກຂໍ້ມູນຮຽບຮ້ອຍ
              </div>
            </Col>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showDanger} onHide={() => setShowDanger(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div className="textfont-size40 text-bold" style={{ color: "red" }}>
              ປ້ອນຂໍ້ມູນບໍ່ຄົບ!
            </div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="5x"
              style={{ color: "red" }}
            />
            <div
              className="textfont-size18 text-bold"
              style={{ marginTop: 40 }}
            >
              ກະລຸນາ ກວດຄືນແຕ່ລະບ໋ອກ!
            </div>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ marginTop: 50 }}
        show={showConfirmLogout}
        onHide={() => setShowConfirmLogout(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>
              ອອກຈາກລະບົບ
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              // className="text-resize5"
              style={{ fontWeight: "bold", fontSize: 24, color: "#6f6f6f" }}
            >
              ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ?
            </div>
          </Col>
          <Col className="d-flex justify-content-between">
            <div />
            <CustomButton
              onClick={() => setShowConfirmLogout(false)}
              type="submit"
              title="ຍົກເລີກ"
            />

            <CustomButton
              confirm
              onClick={() => _onLogout()}
              type="submit"
              title="ຢືນຢັນ"
            />
            <div />
          </Col>
        </Modal.Body>
      </Modal>
      <RegistrationConfirm
        showAddConfirmModal={showAddConfirmModal}
        studentType={studentType}
        param={formParam}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
      />
    </div>
  );
}
