import React, { useEffect, useState } from "react";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Stepper,
	Step,
	StepLabel,
	StepContent,
	Typography,
} from "@material-ui/core";

import useReactRouter from "use-react-router";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { STUDENTS } from "../../apollo/students";
import { UPDATE_STUDENT } from "../../apollo/registrationstudent";
import { formatDate, onConvertGender } from "../../common/super";
import Loading from "../../common/Loading"

export default function CardStudent() {
	const { history, match } = useReactRouter();
	const codeId = match?.params?.student;
	const [data, setData] = useState({});
	const [statusSteps, setStatusSteps] = useState(false);
	const [studentStatus, setStudentStatus] = useState("REQUEST")
	// const [statusRQR, setStatusRQR] = useState();
	// const [statusRR, setStatusRR] = useState();
	const [statusRA, setStatusRA] = useState();
	const [clickCheckbox, setClickCheckbox] = useState();
	const [acceptTerms, setAcceptTerms] = useState();
	const [assessmentYear, setAssessmentYear] = useState("")
	const [dataStudent, { data: student, loading: loadStudentData }] = useLazyQuery(STUDENTS, {
		variables: {
			where: { code: codeId, assessmentYear: assessmentYear },
			orderBy: "createdAt_DESC",
		},
	});

	const [updateStudent] = useMutation(UPDATE_STUDENT);

	useEffect(() => {
		const NOW = new Date().getFullYear();
		const checkMonth = new Date().getMonth() + 1
		let year
		if (checkMonth === 8 || checkMonth === 9 || checkMonth === 10 || checkMonth === 11 || checkMonth === 12) {
			year = NOW + " - " + (parseInt(NOW) + 1);
		} else {
			year = (NOW - 1) + " - " + NOW;
		}
		// setAssessmentYear(year)
		setAssessmentYear("2024 - 2025")
	}, [])

	useEffect(async () => {
		if (assessmentYear === "") return
		await dataStudent();
		setData(student?.students?.data[0]);
		setClickCheckbox(student?.students?.data[0]?.acceptTerms);
		setAcceptTerms(student?.students?.data[0]?.acceptTerms);
	}, [student, data, assessmentYear]);

	useEffect(() => {
		if (data?.statusStep !== "REJECTED") {
			setStatusSteps(true);
		} else {
			setStatusSteps(false);
		}
		setStudentStatus(data?.statusStep)
		// setStudentStatus("ADMIN_APPROVED")
		// setStudentStatus("APPROVED")
		setStatusRA(
			data?.statusStep == "REQUEST"
				? true
				: data?.statusStep == "APPROVED"
					? true
					: false
		);
	}, [data]);

	useEffect(async () => {
		if (clickCheckbox == true) {
			try {
				updateStudent({
					variables: {
						where: { id: data?.id },
						data: { acceptTerms: true },
					},
				});
			} catch (err) {
				console.log("err", err);
			}
		}
		if (clickCheckbox == false) {
			try {
				await updateStudent({
					variables: {
						where: { id: data?.id },
						data: { acceptTerms: false },
					},
				});
			} catch (err) {
				console.log("err", err);
			}
		}
	}, [clickCheckbox, data]);
	// const data = dataStudent?.data?.students?.data[0]

	const _clickCard = () => {
		history.push("/registrationstudentinfo", data);
	};
	// const _clickPresentATemporaryStudentCard = () => {
	// 	history.push("/presentatemporarystudentcard");
	// };
	// const _clickTuition_payment_channels = () => {
	// 	history.push(`/tuitionpaymentchannels`, data);
	// };
	// const _clickQRcodePresentATemporaryStudentCard = () => {
	// 	history.push("/onepay");
	// };
	// const _clickShowDetail = () => {
	// 	history.push("/payment/successpayment", data);
	// // history.push('/tuitionpaymentchannels', data)
	// };

	// const _clickCheckbox = () => {
	// 	setClickCheckbox(false);
	// 	setClickCheckbox(!clickCheckbox);
	// 	setAcceptTerms(!clickCheckbox);
	// };

	if (loadStudentData) return (<Loading />)
	return (
		<div className="container container-sm container-md container-lg container-xl container-fluid">
			<div className="card card-margin-top">
				<div className="card-body">
					{ data?.statusStep == 'REJECTED' && data?.comment ?
						<div className="alert alert-danger">
							{data?.comment} {data?.rejectBy ? `(${data?.rejectBy})` : ''}
						</div> : ""
					}
					<h1 className="card-titlen text-resize5">ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</h1>
					<div className="row justify-content-center">
						<div className="col-md-10 col-lg-10 col-xl-10">
							<div className="card-card-dashboard">
								<div className="user-radius">
									<FontAwesomeIcon
										className="user-radius size-responsive"
										icon={faUserCircle}
										size="7x"
										color={"#888888"}
									/>
								</div>
							</div>
							<div className="card-dashboard-body" style={{ border: "1px solid #ccc" }}>
								<div className="col-xs-12 col-md-6 offset-md-3 text-card-student">
									<div className="text-resize3 row">
										<div className="col-6 col-xs-6 col-md-6 mt-5">
											<b>ຊື່​ນັກ​ສຶກ​ສາ:</b>
										</div>
										<div className="col-6 col-xs-6 col-md-6 mt-5">
											<span>
												{onConvertGender(data?.gender)} {data?.firstNameL}{" "}
												{data?.lastNameL}
											</span>
										</div>
									</div>
									<div className="text-resize3 row">
										<div className="col-6 col-xs-6 col-md-6 mt-2">
											<b>ລະ​ຫັດ​ນັກ​ສຶກ​ສາ:</b>
										</div>
										<div className="col-6 col-xs-6 col-md-6 mt-2">
											<span>{data?.studentNumber}</span>
										</div>
									</div>
									<div className="text-resize3 row">
										<div className="col-6 col-xs-6 col-md-6 mt-2">
											<b>ວັນ​ເດືອນ​ປີ​ເກີດ:</b>
										</div>
										<div className="col-6 col-xs-6 col-md-6 mt-2">
											<span>
												{formatDate(data?.birthday)}
											</span>
										</div>
									</div>
									<div className="text-resize3 row">
										<div className="col-6 col-xs-6 col-md-6 mt-2">
											<b>ແກ້​ໄຂ​ຂໍ​້​ມູນ​ລ່າ​ສຸດ:</b>
										</div>
										<div className="col-6 col-xs-6 col-md-6 mt-2">
											<span>
												{formatDate(data?.createdAt) ?? "-"}
											</span>
										</div>
									</div>
								</div>
								<div
									className="col-xs-12 col-md-6 offset-md-3 text-card-student text-resize3 mt-5"
									style={{
										display: "flex",
										justifyContent: "center",
										cursor: "pointer",
										color: "#057CAE",
										textDecoration: "underline",
									}}
									onClick={() => _clickCard()}
								>
									{statusSteps === false ?
										<span style={{ color: "#FF0000" }}>
											ຖືກປະຕິເສດ{" "}
											<span
												onClick={() =>
													history.push(`/registrationStudent`, { code: data?.code, studentType: data?.studentType })
												}
												style={{
													color: "#057CAE",
													textDecoration: "underline",
													cursor: "pointer",
												}}
											>
												(ເຂົ້າແກ້ໄຂ)
											</span>
										</span>
										: <b>ສະ​ແດງ​ຂໍ​້​ມູນ​ນັກ​ສຶກ​ສາ</b>
									}
								</div>
							</div>
						</div>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div className="w-100">
							<h3 className="text-resize5">ຂັ້ນຕອນການລົງທະບຽນ:</h3>
							<Stepper orientation="vertical" >
								<Step active={statusSteps}>
									<StepLabel>
										<div className="row">
											<div className="col-md-8">
												<h4 className="text-bold colorblack text-resize3 p-0 m-0">
													ຂັ້ນ​ຕອນ​ທີ່ 1 | ລົງ​ທະ​ບຽນປ້ອນແບບ​ຟອມ​ຂໍ້​ມູນ
												</h4>
											</div>
											<div className="col-md-1" style={{ height: 10 }} />
											<div className="col-md-3">
												<div className="text-resize3">
													ສະຖານະ:
													<span style={{ color: "#057CAE", fontWeight: "bold" }}>
														{" "}
														ປ້ອນຂໍ້ມູນສຳເລັດ
													</span>
												</div>
											</div>
										</div>
									</StepLabel>
									<StepContent active={statusSteps}>
										<Typography>
											<div className="text-resize3">
												ທ່ານໄດ້ລົງທະບຽນປ້ອນຟອມລົງທະບຽນເຂົ້າຮຽນສຳເລັດແລ້ວ
												<br /> ກະ​ລຸ​ນາ, ໃຫ້​ທ່ານ​ໄປ​ແຈ້ງ​ຢືນ​ຢັນ​ນຳ​ວິ​ຊາ​ການ ມ​ຊ
											</div>
										</Typography>
									</StepContent>
								</Step>
								<Step active={studentStatus == "ADMIN_APPROVED" || studentStatus == "APPROVED" ? true : false}>
									<StepLabel>
										<div className="row">
											<div className="col-md-8">
												<h4 className="text-bold colorblack text-resize3 p-0 m-0">
													ຂັ້ນ​ຕອນ​ທີ່ 2 | ແຈ້ງວິ​ຊາ​ການ ມ​ຊ
												</h4>
											</div>
											<div className="col-md-1" style={{ height: 10 }} />
											<div className="col-md-3">
												<div className="text-resize3">
													ສະຖານະ:
													{studentStatus == "ADMIN_APPROVED" || studentStatus == "APPROVED" ?
														<span style={{ color: "#057CAE", fontWeight: "bold" }}>
															{" "}
															ຢືນຢັນແລ້ວ
														</span>
														:
														<span style={{ color: "orange", fontWeight: "bold" }}>
															{" "}
															ລໍຖ້າການຢືນຢັນ
														</span>
													}
												</div>
											</div>
										</div>
									</StepLabel>
									<StepContent active={statusSteps}>
										<Typography>
											<div className="text-resize3">
												ໃຊ້ເອກະສານໃນຊອງ ຫຼື ບັດນັກສຶກສາຊົ່ວຄາວ,
												ບັດເຂົ້າຫ້ອງເສັງ,ຮູບທີ່​ຖ່າຍ, ບັດປະຈຳຕົວ ຫຼື ສຳມະໂນ
												ຂອງແທ້ໄປນຳ.
											</div>
										</Typography>
									</StepContent>
								</Step>
								{/* <Step active={studentStatus == "FACULTY_APPROVED" || studentStatus == "APPROVED" ? true : false}> */}
								<Step active={studentStatus == "APPROVED" ? true : false}>
									<StepLabel>
										<div className="row">
											<div className="col-md-8">
												<h4 className="text-bold colorblack text-resize3 p-0 m-0">
													ຂັ້ນ​ຕອນ​ທີ່ 3 | ແຈ້ງຄະນະ
												</h4>
											</div>
											<div className="col-md-1" style={{ height: 10 }} />
											<div className="col-md-3">
												<div className="text-resize3">
													ສະຖານະ:
													{studentStatus == "APPROVED" ?
														<span style={{ color: "#057CAE", fontWeight: "bold" }}>
															{" "}
															ຢືນຢັນແລ້ວ
														</span>
														:
														<span style={{ color: "orange", fontWeight: "bold" }}>
															{" "}
															ລໍຖ້າການຢືນຢັນ
														</span>
													}
												</div>
											</div>
										</div>
									</StepLabel>
									<StepContent active={statusSteps}>
										<Typography>
											<div className="text-resize3">
												ເອກະສານທີ່ຖືໄປຫາຄະນະ <br />
												1. ບັດນັກສຶກສາຊົ່ວຄາວກັບ ທບ 3 <br />
												2. ໃບຈ່າຍເງິນ <br />
												3. ໃບຄະແນນ <br />
												4. ຂໍ້ຕົກລົງ ແລະ ເອກະສານຄັດຕິດ
											</div>
										</Typography>
									</StepContent>
								</Step>
								<Step active={studentStatus == "APPROVED"}>
									<StepLabel>
										<div className="row">
											<div className="col-md-8">
												<h4 className="text-bold colorblack text-resize3 p-0 m-0">
													ຂັ້ນ​ຕອນ​ທີ່ 4 | ສໍາເລັດການລົງທະບຽນ
												</h4>
											</div>
											<div className="col-md-1" style={{ height: 10 }} />
											<div className="col-md-3">
												<div className="text-resize3">
													ສະຖານະ:
													{studentStatus == "APPROVED" ?
														<span style={{ color: "#057CAE", fontWeight: "bold" }}>
															{" "}
															ລົງທະບຽນສຳເລັດ
														</span>
														:
														<span style={{ color: "orange", fontWeight: "bold" }}>
															{" "}
															ລໍຖ້າການຢືນຢັນ
														</span>
													}
												</div>
											</div>
										</div>
									</StepLabel>
									<StepContent active={studentStatus == "APPROVED"}>
										<Typography>
											<div className="text-resize3">
												<b className="text-success">ສໍາເລັດການລົງທະບຽນ</b>
												<div className="text-resize3 mt-3">
													ລະຫັດນັກສຶກສາ ແລະ ລະຫັດຜ່ານ ຂອງລະບົບແອັບ Student
												</div>
												<div className="text-resize3">
													ລະຫັດນັກສຶກສາ: "<strong>{data?.studentNumber}</strong>"
												</div>
												<div className="text-resize3">
													ລະຫັດຜ່ານ: "<strong>{data?.studentNumber}</strong>"
												</div>
												<br />
												<div className="text-resize3">
													ທ່ານສາມາດດາວໂຫຼດແອັບຜ່ານ App store ແລະ Play store
													ພີມຄໍາວ່າ " Smart Learning System "
												</div>
												<div className="text-resize3">
													ຫຼື ທ່ານສາມາດດາວໂຫຼດຜ່ານລິ້ງ:
												</div>
												<div className="text-resize3">
													ສໍາຫຼັບ <strong>Android:</strong>{" "}
													<u>
														<a
															href="https://play.google.com/store/apps/details?id=com.laodevhub.slms&fbclid=IwAR2MpjYjUBabDWPeyZ7Exh0I6Ct25knM38t-apnIL6NPs2hlTgybbqAr-lo"
															target="bank"
														>
															ຄລິກທີ່ນີ້
														</a>
													</u>
												</div>
												<div className="text-resize3">
													ສໍາຫຼັບ <strong>iOS</strong>{" "}
													<u>
														<a
															href="https://apps.apple.com/la/app/slms-system/id1587292699?fbclid=IwAR3iAVtimzmFzp34aTFhONCaYyNgAl7Lqj2F3agk_FozcrDhVC3BPwxLBLE"
															target="bank"
														>
															ຄລິກທີ່ນີ້
														</a>
													</u>
												</div>
											</div>
										</Typography>
									</StepContent>
								</Step>
							</Stepper>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
