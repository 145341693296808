import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUpload,
	faCheckCircle,
	faTimesCircle,
	faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import RegistrationConfirmEn from "./RegistrationConfirmEn";
import useReactRouter from "use-react-router";
import CustomButton from "../../common/CustomButton";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { v4 as uuidv4 } from 'uuid';
import {
	USER_KEY,
	PRIMARY_COLOR
} from "../../consts/index";
import { formatDateYYMMDD, formatDateMMDDYYYY, formatDateDash } from "../../common/super";
import Loading from "../../common/Loading"
import { Formik } from "formik";
import {
	SLMS_FACULTIES,
	SLMS_DEPARTMENTS,
	SLMS_MAJORS,
	CREATE_STUDENT,
	UPDATE_STUDENT,
} from "../../apollo/registrationstudent";
import { PRE_SIGNED_URL } from "../../apollo/registrationstudent";
import { COUNTRIES_LIST } from "../../consts/Countries";
import { STUDENTS, TRIBES } from "../../apollo/students";

import _ from "lodash";

export default function RegistrationStudentEn() {
	const { history } = useReactRouter();
	const [getCode, setGetCode] = useState();
	// const studentType = match.params.studenttype;
	const { studentType, code } = history.location.state
	const [student, setStudent] = useState({});
	const [assessmentYear, setAssessmentYear] = useState("")
	const [phoneHome, setPhoneHome] = useState('')
	const [phoneMobile, setPhoneMobile] = useState('')
	const [parentTelephone, setParentTelephone] = useState('')
	const [emergencyPhone, setEmergencyPhone] = useState('')
	const [fileUploadProgress1, setFileUploadProgress1] = useState(0)
	const [fileUploadProgress2, setFileUploadProgress2] = useState(0)
	const [fileUploadProgress3, setFileUploadProgress3] = useState(0)
	/* <---------- useState faculty ----------> */
	const [selectFacultyIndex, setSelectFacultyIndex] = useState(-1);
	const [facultyId, setFacultyId] = useState();

	/* <-------- useState Department ---------> */
	const [departments, setDepartments] = useState();
	const [departmentName, setDepartmentName] = useState();
	const [majorName, setMajorName] = useState();

	/* <--------  file upload ---------> */
	const [fileUpload, setFileUpload] = useState(null);
	const [passportUrl, setPassportUrl] = useState(null);
	const [passport, setPassport] = useState(null);
	const [qualificationUrl, setQualificationUrl] = useState(null);
	const [qualification, setQualification] = useState(null);
	const [otherUrl, setOtherUrl] = useState(null);
	const [other, setOther] = useState(null);

	/* <--------  general State ---------> */
	const [graduatedYear, setGraduatedYear] = useState([]);
	const [formParam, setFormParam] = useState({});
	const [show, setShow] = useState(false);
	const [showDanger, setShowDanger] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isSave, setIsSave] = useState(true);
	const [click, setClick] = useState("");
	const [type, setType] = useState("");
	const [errorInput, setErrorInput] = useState({});
	const [tribeId, setTribeId] = useState();
	const [renderCode, setRenderCode] = useState();
	const [scholarship, setScholarship] = useState();
	const [dataLocalStorages, setDataLocalStorages] = useState({});
	const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
	const [showConfirmLogout, setShowConfirmLogout] = useState(false);
	const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
	const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
	const [stayTogether, setStayTogether] = useState(false);

	/* <--------- apollo -------> */
	const [loadPreSign, { data: dataPreSign }] = useLazyQuery(PRE_SIGNED_URL);
	const [loadFaculties, { data: facultyApollo }] = useLazyQuery(SLMS_FACULTIES);
	const [createStudent] = useMutation(CREATE_STUDENT);
	const [updateStudent] = useMutation(UPDATE_STUDENT);
	const [loadDataDepartmentPrice, { data: dataDepartmentPrice }] = useLazyQuery(
		SLMS_DEPARTMENTS
	);
	const [loadMajors, { data: majorData }] = useLazyQuery(SLMS_MAJORS)
	const [loadDataStudent, { data, loading }] = useLazyQuery(STUDENTS);

	const _onLogout = () => {
		if (student?.statusStep !== "REJECTED") {
			history.push(`/`);
			localStorage.removeItem(USER_KEY);
		} else {
			history.goBack();
		}
	};
	useEffect(() => {
		const _renderCode = _.split(code, "/");
		setRenderCode(_renderCode[0]);
		setGetCode(getUserDataFromLCStorage()?.code);
		setDataLocalStorages(getUserDataFromLCStorage());
	}, []);

	useEffect(() => {
		let year = [];
		let assessmentYearNow
		const NOW = new Date().getFullYear();
		const checkMonth = new Date().getMonth() + 1
		if (checkMonth === 8 || checkMonth === 9 || checkMonth === 10 || checkMonth === 11 || checkMonth === 12) {
			assessmentYearNow = NOW + " - " + (NOW + 1);
			for (var i = NOW; i >= 1995; i--) {
				year.push(i + " - " + (parseInt(i) + 1));
			}
		} else {
			assessmentYearNow = (NOW - 1) + " - " + NOW;
			for (var i = NOW; i >= 1995; i--) {
				year.push(i - 1 + " - " + (parseInt(i)));
			}
		}
		setGraduatedYear(year);
		// setAssessmentYear(assessmentYearNow)
		setAssessmentYear('2024 - 2025');
	}, []);

	useEffect(() => {
		if (!renderCode || renderCode === "") return;
		loadDataStudent({
			variables: {
				where: { code: renderCode, assessmentYear: assessmentYear },
				orderBy: "createdAt_DESC",
			},
		})
	}, [renderCode])
	const departmentPricesData = dataDepartmentPrice?.slmsDepartments;
	const { data: dataTribe } = useQuery(TRIBES);

	useEffect(() => {
		loadFaculties({
			variables: {
				where: {
					assessmentYear: assessmentYear
				}
			}
		});
		if (!data || data?.students?.data?.length === 0) return;
		setStudent(data?.students?.data[0]);
		const _renderCode = _.split(getCode, "/");
		setRenderCode(_renderCode[0]);
		setStayTogether(data?.students?.data[0]?.stayTogether === false ? false : true);
	}, [data]);

	useEffect(() => {
		if (!_.isEmpty(student)) {
			setPassport(student?.passportUrl?.url);
			setQualification(student?.qualificationUrl?.url);
			// setScorecard(student?.scorecardUrl?.url)
			setPassportUrl(student?.passportUrl?.url);
			setQualificationUrl(student?.qualificationUrl?.url);
			// setScorecardUrl(student?.scorecardUrl?.url)
			setOtherUrl(student?.otherUrl?.url);
			setOther(student?.otherUrl?.url);
			setPhoneHome(student?.phoneHome)
			setPhoneMobile(student?.phoneMobile)
			setParentTelephone(student?.parentTelephone)
			setEmergencyPhone(student?.emergencyPhone)
			_setIsSave();
		}
	}, [student]);

	useEffect(async () => {
		if (student?.faculty?.id) {
			_selectFacultyEffect(
				student?.faculty?.id || dataLocalStorages?.faculty?.id
			);
		}
		if (dataLocalStorages?.faculty?.id) {
			await loadDataDepartmentPrice({
				variables: {
					where: {
						faculty: dataLocalStorages?.faculty?.id,
					},
				},
			});
		}
		if (student?.department?.id) {
			_selectDepartmentEffect(
				student?.department?.id || dataLocalStorages?.department?.id
			);
		}
		if (dataLocalStorages?.department?.id) {
			await loadMajors({
				variables: {
					where: {
						department: dataLocalStorages?.department?.id,
					},
				},
			});
		}
	}, [student?.faculty?.id, student?.department?.id, dataLocalStorages]);

	// select faculty
	const _selectFacalty = (e) => {
		const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
			id: e.target.value,
		});

		if (e?.target?.value) {
			loadDataDepartmentPrice({
				variables: {
					where: {
						faculty: e.target.value,
					},
				},
			});
		}
		setFacultyId(e.target.value);
		setDepartments(facultyApollo?.slmsFaculties[facultyIndex]?.departments);
		setSelectFacultyIndex(facultyIndex);
	};

	const _selectFacultyEffect = async (id) => {
		loadDataDepartmentPrice({
			variables: {
				where: {
					faculty: id,
				},
			},
		});
	};
	const _selectDepartmentEffect = async (id) => {
		loadMajors({
			variables: {
				where: {
					department: id,
				},
			},
		});
	};

	// select department
	const _selectDepartment = (e) => {
		const departmentIndex = _.findIndex(departmentPricesData, {
			id: e.target.value,
		});
		if (e?.target?.value) {
			loadMajors({
				variables: {
					where: {
						department: e.target.value,
					},
				},
			});
		}
		setDepartmentName(departmentPricesData[departmentIndex]?.name);
	};

	const _selectMajor = (e) => {
		const majorIndex = _.findIndex(majorData?.slmsMajors, {
			id: e.target.value,
		});
		setMajorName(majorData?.slmsMajors[majorIndex]?.name);
	};

	// useEffect province from database
	useEffect(() => {
		const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
			id: student?.faculty?.id || dataLocalStorages?.faculty?.id,
		});
		setSelectFacultyIndex(facultyIndex);
		setDepartmentName(dataLocalStorages?.department?.name);
		setMajorName(dataLocalStorages?.major?.name);
	}, [student, data, student, facultyApollo]);

	useEffect(() => {
		_setIsSave();
		// }, [passportUrl, otherUrl, qualificationUrl]);
	}, [passportUrl, qualificationUrl]);

	useEffect(() => {
		if (dataPreSign?.preSignedUrl) {
			fileGetResign(dataPreSign?.preSignedUrl);
		} else {
			setIsLoading(false)
		}
	}, [dataPreSign]);

	const fileGetResign = async (data) => {
		try {
			await axios({
				method: "put",
				url: data.url,
				data: fileUpload,
				headers: {
					"Content-Type": " file/*; image/*",
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
					"Access-Control-Allow-Headers":
						"Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
				},
				/* ສະແດງເປີເຊັນຂໍ້ມູນທີ່ອັບໂຫລດແລ້ວ  */
				onUploadProgress: function (progressEvent) {
					let percentComplete = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
					if (click === "1") {
						setIsLoading(true)
						setFileUploadProgress1(percentComplete)
					}
					if (click === "2") {
						setIsLoading(true)
						setFileUploadProgress2(percentComplete)
					}
					if (click === "3") {
						setIsLoading(true)
						setFileUploadProgress3(percentComplete)
					}
					if (click === "1" && percentComplete === 100) {
						setPassportUrl(data?.url.split("?")[0]);
						setFileUploadProgress1(0)
						setIsLoading(false)
					}
					if (click === "2" && percentComplete === 100) {
						setQualificationUrl(data?.url.split("?")[0]);
						setFileUploadProgress2(0)
						setIsLoading(false)
					}
					if (click === "3" && percentComplete === 100) {
						setOtherUrl(data?.url.split("?")[0]);
						setFileUploadProgress3(0)
						setIsLoading(false)
					}
				}
			});
		} catch (error) {
			setIsLoading(false)
		}
	};

	// ເລືອກໄຟລຈາກ ຄອມ
	const _handleChangeFile = async (acceptedFiles) => {
		try {
			// ໄປຂໍ url ຈາກ s3
			// setIsLoading(true)
			let data = uuidv4() + '.' + acceptedFiles?.target?.files[0].type.split('/')[1];
			switch (click) {
				case "1":
					setPassport(data);
					break;
				case "2":
					setQualification(data);
					break;
				case "3":
					setOther(data);
					break;
				default:
					setPassport(data);
					break;
			}
			setFileUpload(acceptedFiles?.target?.files[0]);
			loadPreSign({
				variables: {
					name: data,
				},
			});
		} catch (error) {
			setIsLoading(false)
		}
	};
	const _setIsSave = () => {
		// if (passportUrl && otherUrl && qualificationUrl) {
		if (passportUrl && qualificationUrl) {
			setIsSave(false);
		} else {
			setIsSave(true);
		}
	};

	const _selectTribe = (tribe) => {
		setTribeId(dataTribe?.tribes[tribe.target.value]?.id);
	};

	const selectScholarship = (e) => {
		setScholarship(e.target.value);
	};

	const _clickStayTogether = (e) => {
		setStayTogether(!stayTogether);
	};
	const handlePhoneHomeChange = evt => {
		const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

		if (rx_live.test(evt.target.value)) {
			setPhoneHome(evt.target.value)
		}
	}
	const handlePhoneMobileChange = evt => {
		const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

		if (rx_live.test(evt.target.value)) {
			setPhoneMobile(evt.target.value)
		}
	}
	const handleParentTelephoneChange = evt => {
		const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

		if (rx_live.test(evt.target.value)) {
			setParentTelephone(evt.target.value)
		}
	}
	const handleEmergencyPhoneChange = evt => {
		const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

		if (rx_live.test(evt.target.value)) {
			setEmergencyPhone(evt.target.value)
		}
	}
	const handleSubmit2 = async (event, values) => {
		event.preventDefault();
		var errors = {};
		if (studentType == "IN_PLAN_EN") {
			if (values.email == "") {
				delete values.email;
			}
			// if (phoneHome == "") {
			//   delete values.phoneHome;
			// }
			if (values.lastNameL == "") {
				delete values.lastNameL;
			}
			if (values.scholarshipAgreementNo == "") {
				delete values.scholarshipAgreementNo;
			}
			if (values.transferLetterNo === "") {
				delete values.transferLetterNo;
			}
			if (values.scholarshipFrom === "") {
				delete values.scholarshipFrom
			}
			if (values.outPlanScholarship === "") {
				delete values.outPlanScholarship
			}

			if (!values.scholarship) {
				errors.scholarship = "Please select scholarship"
			}
			if (!values.graduateFromCountry) {
				errors.graduateFromCountry = "Please select graduate country"
			}
			if (!values.graduateFromProvince) {
				errors.graduateFromProvince = "Please input graduate province/state"
			}
			if (!values.graduateFromSchool) {
				errors.graduateFromSchool = "Please input graduate school"
			}
			if (!values.semesterYear) {
				errors.semesterYear = "Please input academic year"
			}

			delete values.outPlanGraduateFromCountry;
			delete values.outPlanGraduateFromProvince;
			delete values.outPlanGraduateFromSchool;
			delete values.outPlanSemesterYear;
		}
		delete values.examCode;
		if (values.email == "") {
			delete values.email;
		}
		// if (phoneHome == "") {
		//   delete values.phoneHome;
		// }
		if (values.lastNameL == "") {
			delete values.lastNameL;
		}
		delete values.otherScholarship;
		// delete values.stayTogether;
		delete values.acceptTerms;

		if (studentType == "OUT_PLAN_EN") {
			if (values.email == "") {
				delete values.email;
			}
			// if (phoneHome == "") {
			//   delete values.phoneHome;
			// }
			if (values.lastNameL == "") {
				delete values.lastNameL;
			}
			delete values.graduateFromCountry;
			delete values.graduateFromProvince;
			delete values.graduateFromSchool;
			// delete values.scholarship;
			if (values.scholarshipAgreementNo == "") {
				delete values.scholarshipAgreementNo;
			}
			delete values.semesterYear;
			if (values.transferLetterNo === "") {
				delete values.transferLetterNo;
			}

			if (!values.outPlanScholarship) {
				errors.outPlanScholarship = "Please select scholarship"
			}
			if (!values.outPlanGraduateFromCountry) {
				errors.outPlanGraduateFromCountry = "Please select graduate country"
			}
			if (!values.outPlanGraduateFromProvince) {
				errors.outPlanGraduateFromProvince = "Please input graduate province/state"
			}
			if (!values.outPlanGraduateFromSchool) {
				errors.outPlanGraduateFromSchool = "Please input graduate school"
			}
			if (!values.outPlanSemesterYear) {
				errors.outPlanSemesterYear = "Please input academic year"
			}
		}

		if (dataLocalStorages?.educationLevel === "BACHELOR_CONTINUING") {
			if (!values.graduatedMajor) {
				errors.graduatedMajor = "Please input graduated major";
			}
		}

		if (values.stayTogether === false) {
			if (!values.emergencyCountry) {
				errors.emergencyCountry = "Please input emergency country";
			}
			if (!values.emergencyProvince) {
				errors.emergencyProvince = "Please input emergency province/state";
			}
			if (!values.emergencyDistrict) {
				errors.emergencyDistrict = "Please input emergency district";
			}
			if (!values.emergencyVillage) {
				errors.emergencyVillage = "Please input emergency village";
			}
		}

		if (!values.studentInfo) {
			errors.studentInfo = "Please select student's information"
		}
		if (!values.educationLevel) {
			errors.educationLevel = "Please select level"
		}
		if (!values.firstNameE) {
			errors.firstNameE = "Please input given name"
		}
		if (!values.lastNameE) {
			errors.lastNameE = "Please input family name"
		}
		if (!values.nationality) {
			errors.nationality = "Please select nationality"
		}
		if (!values.religion) {
			errors.religion = "Please select religion"
		}
		// if(!phoneHome) {
		//   errors.phoneHome = "Please input your home phone"
		// }
		if (!phoneMobile) {
			errors.phoneMobile = "Please input your mobile phone"
		}
		if (!values.country) {
			errors.country = "Please input current country"
		}
		if (!values.province) {
			errors.province = "Please input current province/state"
		}
		if (!values.district) {
			errors.district = "Please input current district"
		}
		if (!values.village) {
			errors.village = "Please input current village"
		}
		if (!values.birthCountry) {
			errors.birthCountry = "Please select birth country"
		}
		if (!values.birthProvince) {
			errors.birthProvince = "Please input birth province/state"
		}
		if (!values.birthDistrict) {
			errors.birthDistrict = "Please input birth district"
		}
		if (!values.birthVillage) {
			errors.birthVillage = "Please input birth village"
		}
		if (!values.fatherNameAndSurname) {
			errors.fatherNameAndSurname = "Please input father name and surname"
		}
		if (!values.motherNameAndSurname) {
			errors.motherNameAndSurname = "Please input mother name and surname"
		}
		if (!parentTelephone) {
			errors.parentTelephone = "Please input parents mobile phone"
		}
		if (!values.parentCountry) {
			errors.parentCountry = "Please select parents country"
		}
		if (!values.parentProvince) {
			errors.parentProvince = "Please input parent province/state"
		}
		if (!values.parentDistrict) {
			errors.parentDistrict = "Please input parents district"
		}
		if (!values.parentVillage) {
			errors.parentVillage = "Please input parent village"
		}
		if (!values.contactName) {
			errors.contactName = "Please input contact name"
		}
		if (!emergencyPhone) {
			errors.emergencyPhone = "Please input emergency phone"
		}
		setErrorInput(errors)
		console.log(errors)

		let address = {
			country: values.country,
			province: values.province,
			district: values.district,
			village: values.village,
		};
		values = {
			...values,
			address: address,
		};
		delete values.country;
		delete values.province;
		delete values.village;
		delete values.district;

		let birthAddress = {
			country: values.birthCountry,
			province: values.birthProvince,
			district: values.birthDistrict,
			village: values.birthVillage,
		};
		values = {
			...values,
			birthAddress: birthAddress,
		};
		delete values.birthCountry;
		delete values.birthProvince;
		delete values.birthVillage;
		delete values.birthDistrict;

		let parentAddress = {
			country: values.parentCountry,
			province: values.parentProvince,
			district: values.parentDistrict,
			village: values.parentVillage,
		};
		values = {
			...values,
			parentAddress: parentAddress,
		};
		delete values.parentCountry;
		delete values.parentProvince;
		delete values.parentVillage;
		delete values.parentDistrict;

		let emergencyAddress = {};
		if (stayTogether === false) {
			emergencyAddress = {
				country: values.emergencyCountry,
				province: values.emergencyProvince,
				district: values.emergencyDistrict,
				village: values.emergencyVillage,
			};
			values = {
				...values,
				emergencyAddress: emergencyAddress,
			};
		}
		delete values.emergencyCountry;
		delete values.emergencyProvince;
		delete values.emergencyDistrict;
		delete values.emergencyVillage;

		if (dataLocalStorages?.educationLevel !== "BACHELOR_CONTINUING") {
			delete values.graduatedMajor;
		}

		let data = {
			...values,
			yearLevel: "1",
			examCode: dataLocalStorages?.id,
			faculty: facultyApollo?.slmsFaculties[selectFacultyIndex]?.id,
			department: values.department,
			major: values.major,
			facultyname:
				facultyApollo?.slmsFaculties[selectFacultyIndex]?.name,
			departmentname: departmentName,
			majorname: majorName,
			// tribe: values.tribe,
			passportUrl: { url: passport },
			qualificationUrl: { url: qualification },
			otherUrl: { url: other },
			// scorecardUrl: { url: scorecardUrl },
			phoneHome: phoneHome,
			phoneMobile: phoneMobile,
			parentTelephone: parentTelephone,
			emergencyPhone: emergencyPhone,
			studentType: studentType,
			statusStep: student?.statusStep ?? "SEND_FORM",
			// statusStepUpdate: student?.statusStep,
			scholarship: studentType === "OUT_PLAN_EN"
				? values.outPlanScholarship
				: values.scholarship,
			graduateFromCountry: values.outPlanGraduateFromCountry
				? values.outPlanGraduateFromCountry
				: values.graduateFromCountry,
			graduateFromProvince: values.outPlanGraduateFromProvince
				? values.outPlanGraduateFromProvince
				: values.graduateFromProvince,
			graduateFromSchool: values.outPlanGraduateFromSchool
				? values.outPlanGraduateFromSchool
				: values.graduateFromSchool,
			semesterYear: values.outPlanSemesterYear
				? values.outPlanSemesterYear
				: values.semesterYear,
			id: student?.id,
		};
		delete data.outPlanScholarship;
		delete data.outPlanGraduateFromCountry;
		delete data.outPlanGraduateFromProvince;
		delete data.outPlanGraduateFromSchool;
		delete data.outPlanSemesterYear;
		delete data.birthDistrict;
		delete data.birthVillage;
		delete data.district;
		delete data.village;
		if (student) {
			data.id = student.id;
		}
		let paramQL = {
			data,
		};
		setFormParam(paramQL);
		const checkData = _.isEmpty(errors)
		if (!checkData) {
			setShowDanger(true)
			setTimeout(() => {
				setShowDanger(false)
			}, 2500);
			return
		}
		if (type == "save") {
			try {
				if (!_.isEmpty(student)) {
					delete data.facultyname;
					delete data.departmentname;
					delete data.majorname;
					// delete data.statusStepUpdate;
					delete data.statusStep;
					// setShow(true);
					const updateData = await updateStudent({
						variables: {
							where: { id: student?.id },
							data: data,
						},
					});
					if (updateData?.data?.updateStudent?.id) {
						setShow(true);
						setTimeout(() => {
							setShow(false);
							window.location.reload();
						}, 1000);
					}
				}
				if (_.isEmpty(student)) {
					delete data.facultyname;
					delete data.departmentname;
					delete data.majorname;
					delete data.statusStep;
					// setShow(true);
					const createData = await createStudent({ variables: paramQL });
					if (createData?.data?.createStudent?.id) {
						setShow(true);
						setTimeout(() => {
							setShow(false);
							window.location.reload();
						}, 1000);
					}
				}
			} catch (err) { }
		} else if (type == "verify") {
			_handleShowAddConfirmModalShow();
		}
	}

	if (loading) return (<Loading />)
	return (
		<div className="container mt-5 mb-5">
			<div className="col-sm-12 card p-4" style={{ color: "#707070" }}>
				{/* {studentData && */}
				{
					<Formik
						enableReinitialize={true}
						initialValues={{
							examCode: dataLocalStorages?.id ?? "",
							faculty: student?.faculty?.id ?? dataLocalStorages?.faculty?.id,
							department:
								student?.department?.id ?? dataLocalStorages?.department?.id,
							major:
								student?.major?.id ?? dataLocalStorages?.major?.id,
							code: renderCode,
							studentNumber: getCode,
							studentInfo: student?.studentInfo ?? "",
							educationLevel: student?.educationLevel ?? dataLocalStorages?.educationLevel,
							firstNameL: student?.firstNameL ?? dataLocalStorages?.firstNameL,
							lastNameL: student?.lastNameL ?? dataLocalStorages?.lastNameL,
							firstNameE: student?.firstNameE ?? dataLocalStorages?.firstNameE,
							lastNameE: student?.lastNameE ?? dataLocalStorages?.lastNameE,
							birthday: student?.birthday ?? dataLocalStorages?.birthday,
							dateTransferLetterNo:
								formatDateYYMMDD(student?.dateTransferLetterNo) ?? "",
							dateScholarshipAgreementNo:
								formatDateYYMMDD(student?.dateScholarshipAgreementNo) ?? "",
							gender: student?.gender ?? dataLocalStorages?.gender ?? "MALE",
							healthStatus: student?.healthStatus ?? "GOOD",
							health: student?.health ?? "",
							maritualStatus: student?.maritualStatus ?? "SINGLE",
							nationality: student?.nationality ?? "",
							// tribe: student?.tribe?.id ?? "",
							religion: student?.religion ?? "",
							// phoneHome: phoneHome ?? "",
							// phoneMobile:
							//   phoneMobile ?? dataLocalStorages?.phoneMobile,
							email: student?.email ?? "",
							country: student?.address?.country ?? "",
							province: student?.address?.province ?? "",
							district: student?.address?.district ?? "",
							village: student?.address?.village ?? "",
							birthCountry:
								student?.birthAddress?.country ??
								dataLocalStorages?.birthAddress?.country,
							birthProvince:
								student?.birthAddress?.province ??
								dataLocalStorages?.birthAddress?.province,
							birthDistrict:
								student?.birthAddress?.district ??
								dataLocalStorages?.birthAddress?.district,
							birthVillage:
								student?.birthAddress?.village ??
								dataLocalStorages?.birthAddress?.village,
							parentCountry: student?.parentAddress?.country ?? "",
							parentProvince: student?.parentAddress?.province ?? "",
							parentDistrict: student?.parentAddress?.district ?? "",
							parentVillage: student?.parentAddress?.village ?? "",
							scholarship: student?.scholarship ?? "",
							scholarshipFrom: student?.scholarshipFrom ?? "",
							outPlanScholarship: student?.scholarship ?? "",
							otherScholarship: student?.otherScholarship ?? "",
							graduateFromCountry: student?.graduateFromCountry ?? "",
							graduateFromProvince: student?.graduateFromProvince ?? "",
							graduateFromSchool: student?.graduateFromSchool ?? "",
							graduatedMajor: student?.graduatedMajor ?? "",
							semesterYear: student?.semesterYear ?? "2023 - 2024",
							outPlanGraduateFromCountry: student?.graduateFromCountry ?? "",
							outPlanGraduateFromProvince: student?.graduateFromProvince ?? "",
							outPlanGraduateFromSchool: student?.graduateFromSchool ?? "",
							outPlanSemesterYear: student?.semesterYear ?? "2023 - 2024",
							contactName:
								student?.contactName ?? dataLocalStorages?.contactName,
							// emergencyPhone:
							//   student?.emergencyPhone ?? dataLocalStorages?.emergencyPhone,
							stayTogether: student?.stayTogether ?? false,
							emergencyCountry: student?.emergencyAddress?.country,
							emergencyProvince: student?.emergencyAddress?.province,
							emergencyDistrict: student?.emergencyAddress?.district,
							emergencyVillage: student?.emergencyAddress?.village,
							acceptTerms: student?.acceptTerms ?? false,
							dormitory: student?.dormitory ?? "YES",
							fatherNameAndSurname: student?.fatherNameAndSurname ?? "",
							motherNameAndSurname: student?.motherNameAndSurname ?? "",
							// parentTelephone: student?.parentTelephone ?? "",
							transferLetterNo: student?.transferLetterNo ?? "",
							scholarshipAgreementNo: student?.scholarshipAgreementNo ?? "",
							adminApprovedBy: "",
							facultyApprovedBy: "",
						}}
						validate={(values) => {

						}}
					>
						{({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
							<form onSubmit={(event) => handleSubmit2(event, values)}>
								<div className="card_registrationStudents">
									<div className="card-body card_registerstrationStudents_body">
										{/* <span className="float-right textfont-size18">
											<a
												href="https://nuol.s3.ap-southeast-1.amazonaws.com/dev/khumueUP2022-2023-EN.pdf"
												target="_blank"
												rel="noreferrer"
											>
												<u>Manual</u>
											</a>
										</span> */}
										{student?.statusStep == 'REJECTED' && student?.comment ?
											<div className="alert alert-danger mt-5">
												{student?.comment} {student?.rejectBy ? `(${student?.rejectBy})` : ''}
											</div> : ""
										}
										<h6 className="mt-5 textForm">
											Registration Form
										</h6>
										<div className="card bg-register"><b>Student Information</b></div>

										<div className="row margin-resgintrationStudent">
											<div className="col-md-4 mt-2">
												<Form.Label fontSize="14" className="text-bold">
													Registration Number <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													disabled
													placeholder="Enter your registration number"
													name="studentNumber"
													value={values.studentNumber}
													onChange={handleChange}
												/>
											</div>
											<div className="col-md-4 mt-2">
												<Form.Label fontSize="14" className="text-bold">
													Faculty <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="faculty"
													isInvalid={errors.faculty}
													value={values.faculty}
													onChange={(e) => {
														handleChange(e);
														_selectFacalty(e);
													}}
													disabled
												>
													<option disabled={false} value="">
														---Select---
													</option>
													{facultyApollo?.slmsFaculties.map((x, index) => (
														<option key={"faculty" + index} value={x.id}>
															{x.name}
														</option>
													))}
												</Form.Control>
											</div>
											<div className="col-md-4 mt-2">
												<Form.Label fontSize="14" className="text-bold">
													Department
													<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="department"
													isInvalid={errors.department}
													value={values.department}
													onChange={(e) => {
														handleChange(e);
														_selectDepartment(e);
													}}
													disabled
												>
													<option value="">---Select---</option>
													{departmentPricesData &&
														departmentPricesData?.map((x, index) => (
															<option key={"department" + index} value={x.id}>
																{x.name}
															</option>
														))}
												</Form.Control>
											</div>
											<div className="col-md-4 mt-2">
												<Form.Label fontSize="14" className="text-bold">
													Major
													<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="major"
													isInvalid={errors.major}
													value={values.major}
													onChange={(e) => {
														handleChange(e);
														_selectMajor(e);
													}}
													disabled
												>
													<option value="">---Select---</option>
													{majorData?.slmsMajors &&
														majorData?.slmsMajors?.map((x, index) => (
															<option key={index} value={x.id}>
																{x.name}
															</option>
														))}
												</Form.Control>
											</div>
											<div className="col-md-4 mt-2">
												<Form.Label fontSize="14" className="text-bold" name="">
													Student's information{" "}
													<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="studentInfo"
													value={values.studentInfo}
													isInvalid={errorInput && errorInput?.studentInfo}
													onChange={handleChange}
												>
													<option value="">---Select---</option>
													<option value="EMPLOYEE">Employed</option>
													<option value="ORDINARY_STUDENT">
														Normal Student
													</option>
												</Form.Control>
											</div>
											<div className="col-md-4 mt-2">
												<Form.Label fontSize="14" className="text-bold">
													Level <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													value={values.educationLevel}
													name="educationLevel"
													isInvalid={errorInput && errorInput?.educationLevel}
													onChange={handleChange}
													disabled
												>
													<option value="">---Select---</option>
													<option value="DEPLOMA">Associate Degree</option>
													<option value="BACHELOR_DEGREE">Bachelor Degree</option>
													<option value="BACHELOR_CONTINUING">
														Continuing Bachelor Degree
													</option>
												</Form.Control>
											</div>
										</div>
										<div className="card bg-register mt-5"><b>General Information</b></div>
										<div className="row margin-resgintrationStudent">
											<div className="col-xs-12 col-md-2 mt-3">
												<label className="text-bold">
													Gender <span className="text-danger">*</span>
												</label>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Male"
														type="radio"
														value="MALE"
														id="gender-radio"
														name="gender"
														disabled
														onChange={handleChange}
														checked={values.gender == "MALE" ? true : false}
													/>
												</Form.Group>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Female"
														type="radio"
														value="FEMALE"
														id="gender-radio"
														name="gender"
														disabled
														onChange={handleChange}
														checked={values.gender == "FEMALE" ? true : false}
													/>
												</Form.Group>
											</div>
											<div className="col-xs-12 col-md-6" />
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Given name <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													value={values.firstNameE}
													maxLength="20"
													placeholder="Enter your given name"
													name="firstNameE"
													disabled={dataLocalStorages?.firstNameE ? true : false}
													onChange={handleChange}
													isInvalid={errorInput && errorInput?.firstNameE}
												/>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Family name <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													value={values.lastNameE}
													maxLength="20"
													placeholder="Enter your family name"
													name="lastNameE"
													disabled={dataLocalStorages?.lastNameE ? true : false}
													onChange={handleChange}
													isInvalid={errorInput && errorInput?.lastNameE}
												/>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Date of birth <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													value={formatDateDash(values.birthday)}
													placeholder="12/02/2000"
													name="birthday"
													onChange={handleChange}
													isInvalid={errors.birthday}
													disabled
												/>
												{/* disabled={dataLocalStorages?.birthday ? true : false} */}
											</div>
											<div className="col-xs-12 col-md-2 mt-3">
												<label className="text-bold">
													Health <span className="text-danger">*</span>
												</label>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Normal"
														type="radio"
														value="GOOD"
														id="healthStatus-radio"
														name="healthStatus"
														onChange={handleChange}
														checked={
															values.healthStatus == "GOOD" ? true : false
														}
													/>
												</Form.Group>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Disability"
														type="radio"
														value="BAD"
														id="healthStatus-radio"
														name="healthStatus"
														onChange={handleChange}
														checked={
															values.healthStatus == "BAD" ? true : false
														}
													/>
												</Form.Group>
											</div>
											{values.healthStatus == "BAD" ?
												<div className="col-md-12 mt-3">
													<label>Disability type</label>
													<Form.Control
														type="text"
														value={values.health}
														name="health"
														placeholder="Example: bind"
														onChange={handleChange}
														disabled={values.healthStatus == "GOOD" ? true : false}
													/>
												</div>
												: ''}
											<div className="col-md-12 mt-3" />
											<div className="col-xs-12 col-md-2 mt-3">
												<label className="text-bold">
													Marital status <span className="text-danger">*</span>
												</label>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Single"
														type="radio"
														value="SINGLE"
														id="maritualStatus-radio"
														name="maritualStatus"
														onChange={handleChange}
														checked={
															values.maritualStatus == "SINGLE" ? true : false
														}
													/>
												</Form.Group>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Married"
														type="radio"
														id="maritualStatus-radio"
														value="MARRIAGE"
														name="maritualStatus"
														onChange={handleChange}
														checked={
															values.maritualStatus == "MARRIAGE" ? true : false
														}
													/>
												</Form.Group>
											</div>
											<div className="col-md-12 mt-3" />
											<div className="col-xs-12 col-md-2 mt-3">
												<label className="text-bold">
													Dormitory <span className="text-danger">*</span>
												</label>
											</div>
											<div className="col-xs-6 col-md-2 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="In dormitory"
														type="radio"
														value="YES"
														id="dormitory-radio"
														name="dormitory"
														onChange={handleChange}
														checked={values.dormitory == "YES" ? true : false}
													/>
												</Form.Group>
											</div>
											<div className="col-xs-12 col-md-6 mt-3">
												<Form.Group>
													<Form.Check
														inline
														label="Off dormitory"
														type="radio"
														id="dormitory-radio"
														value="NO"
														name="dormitory"
														onChange={handleChange}
														checked={values.dormitory == "NO" ? true : false}
													/>
												</Form.Group>
											</div>
											<div className="col-md-6 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Nationality <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													value={values.nationality}
													name="nationality"
													onChange={handleChange}
													isInvalid={errorInput && errorInput?.nationality}
												>
													<option value="">---Select---</option>
													{COUNTRIES_LIST.map((nationalities, index) => (
														<option
															key={"nationalities" + index}
															value={nationalities?.nationality}
														>
															{nationalities?.nationality}
														</option>
													))}
												</Form.Control>
											</div>
											<div className="col-md-6 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Religion <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													value={values.religion}
													name="religion"
													onChange={handleChange}
													isInvalid={errorInput && errorInput?.religion}
												>
													<option value="">---Select---</option>
													<option value="​ພຸດ">Buddhism</option>
													<option value="ຜີ">Ghost</option>
													<option value="ຄຣິສະ​ຕຽນ">Christianity</option>
													<option value="ອິດສະລາມ">Islam</option>
												</Form.Control>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Home phone
												</Form.Label>
												<Form.Control
													type="tel"
													pattern='[+-]?\d+(?:[.,]\d+)?'
													className="form-control"
													value={phoneHome}
													maxLength="15"
													placeholder="Please input your home phone"
													name="phoneHome"
													// isInvalid={errorInput && errorInput?.phoneHome}
													onChange={handlePhoneHomeChange}
												/>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Mobile phone <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="tel"
													pattern='[+-]?\d+(?:[.,]\d+)?'
													className="form-control"
													value={phoneMobile}
													maxLength="15"
													placeholder="Please input your mobile phone"
													name="phoneMobile"
													isInvalid={errorInput && errorInput?.phoneMobile}
													onChange={handlePhoneMobileChange}
												/>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Email
												</Form.Label>
												<Form.Control
													type="text"
													value={values.email}
													maxLength="100"
													placeholder="Please input email"
													name="email"
													isInvalid={errorInput && errorInput?.email}
													onChange={handleChange}
												/>
												<Form.Control.Feedback type="invalid">
													{errorInput && errorInput?.email}
												</Form.Control.Feedback>
											</div>
										</div>
										<div className="card bg-register mt-5"><b>Current address</b></div>
										<div className="row margin-resgintrationStudent">
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Country <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="country"
													onChange={handleChange}
													value={values.country}
													isInvalid={errorInput && errorInput?.country}
													id="disabledSelect"
												>
													<option value="">---Select---</option>
													{COUNTRIES_LIST.map((countries, index) => (
														<option
															key={"countries" + index}
															value={countries?.en_short_name}
														>
															{countries?.en_short_name}
														</option>
													))}
												</Form.Control>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Province/State <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="province"
													isInvalid={errorInput && errorInput?.province}
													placeholder="Please input province"
													onChange={handleChange}
													value={values.province}
													maxLength="50"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													District <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="district"
													isInvalid={errorInput && errorInput?.district}
													placeholder="Please input district"
													onChange={handleChange}
													value={values.district}
													maxLength="50"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Vilage <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="village"
													isInvalid={errorInput && errorInput?.village}
													placeholder="Please input village"
													onChange={handleChange}
													value={values.village}
													maxLength="40"
												/>
											</div>
										</div>
										<div className="card bg-register mt-5"><b>Place of Birth</b></div>
										<div className="row margin-resgintrationStudent">
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Country <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="birthCountry"
													onChange={handleChange}
													isInvalid={errorInput && errorInput?.birthCountry}
													value={values.birthCountry}
												// disabled
												>
													<option value="">---Select---</option>
													{COUNTRIES_LIST.map((countries, index) => (
														<option
															key={"countries" + index}
															value={countries?.en_short_name}
														>
															{countries?.en_short_name}
														</option>
													))}
												</Form.Control>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Province/State <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="birthProvince"
													isInvalid={errorInput && errorInput?.birthProvince}
													placeholder="Please input province"
													onChange={handleChange}
													value={values.birthProvince}
													maxLength="50"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													District <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="birthDistrict"
													value={values.birthDistrict}
													isInvalid={errorInput && errorInput?.birthDistrict}
													placeholder="Please input district"
													onChange={handleChange}
													maxLength="50"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Village <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="birthVillage"
													value={values.birthVillage}
													isInvalid={errorInput && errorInput?.birthVillage}
													placeholder="Please input"
													onChange={handleChange}
													maxLength="40"
												// disabled
												/>
											</div>
											<div className="col-md-4 mt-3" />
										</div>
										{studentType !== "IN_PLAN_EN" ? (
											<div>
												<div className="card bg-register mt-5">
													<b>For self-financing student</b>
												</div>
												<div className="row margin-resgintrationStudent">
													<div className="col-md-12 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Scholarship <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															id="disabledSelect"
															name="outPlanScholarship"
															value={values.outPlanScholarship}
															isInvalid={errorInput && errorInput?.outPlanScholarship}
															onChange={(e) => {
																selectScholarship(e);
																handleChange(e);
															}}
														>
															<option value="">Select Scholarship</option>
															<option value="ORGANIZATION_IN_LAOPDR_MINISTRIES">
																The organization in the Lao PDR(Ministries)
															</option>
															<option value="ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION">
																The organization in the Lao PDR(Provincial Education Division)
															</option>
															<option value="HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD">
																Higher Education institution form abroad(Universities)
															</option>
															<option value="PERSONAL_FINANCIAL">
																Personal financial
															</option>
														</Form.Control>
													</div>
													<div className="col-md-12 mt-3">
														<Form.Control
															type={
																values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_MINISTRIES"
																	|| values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION"
																	|| values.outPlanScholarship === "HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD"
																	? "text" : "hidden"
															}
															placeholder="Please input scholarship from which organization"
															value={values.scholarshipFrom}
															name="scholarshipFrom"
															isInvalid={errors.scholarshipFrom}
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Country <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															name="outPlanGraduateFromCountry"
															isInvalid={errorInput && errorInput?.outPlanGraduateFromCountry}
															onChange={handleChange}
															value={values.outPlanGraduateFromCountry}
														>
															<option value="">---Select---</option>
															{COUNTRIES_LIST.map((countries, index) => (
																<option
																	key={"countries" + index}
																	value={countries?.en_short_name}
																>
																	{countries?.en_short_name}
																</option>
															))}
														</Form.Control>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Province/State <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															placeholder="Please input graduate province"
															value={values.outPlanGraduateFromProvince}
															name="outPlanGraduateFromProvince"
															isInvalid={errorInput && errorInput?.outPlanGraduateFromProvince}
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Graduate from <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															placeholder="Please input graduate from"
															value={values.outPlanGraduateFromSchool}
															name="outPlanGraduateFromSchool"
															isInvalid={errorInput && errorInput?.outPlanGraduateFromSchool}
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Academic year <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															name="outPlanSemesterYear"
															isInvalid={errorInput && errorInput?.outPlanSemesterYear}
															onChange={handleChange}
															value={values.outPlanSemesterYear}
														>
															{/* <option>---Select---</option> */}
															{graduatedYear.map((x, index) => {
																return (
																	<option value={x} key={index}>
																		{x}
																	</option>
																);
															})}
														</Form.Control>
													</div>
												</div>
											</div>
										) : (
											<div>
												<div className="card bg-register mt-5">
													<b>For student/Civil servant who receives scholarship from an organization</b>
												</div>
												<div className="row margin-resgintrationStudent">
													<div className="col-md-12 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Scholarship <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															id="disabledSelect"
															name="scholarship"
															value={values.scholarship}
															isInvalid={errorInput && errorInput?.scholarship}
															onChange={(e) => {
																selectScholarship(e);
																handleChange(e);
															}}
														>
															<option value="">Select Scholarship</option>
															<option value="NUOL_SCHOLARSHIP">
																NUOL'S talent student
															</option>
															<option value="ETHNIC_STUDENT_OF_PROVINCIAL">
																Provincial ethnic student
															</option>
															<option value="RED_DIPLOMA_SCHOLARSHIP">
																First-class honours
															</option>
															<option value="ANTIONAL_STUDENT_SCHOLARSHIP">
																Outstanding student in general subject/talent student
															</option>
															<option value="MINISTRY_OF_EDUCATION_AND_SPORTS">
																Ministry of Education and Sports
															</option>
															<option value="OTHER_SHOLARSHIP">
																Other scholarship
															</option>
														</Form.Control>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Country <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															name="graduateFromCountry"
															isInvalid={errorInput && errorInput?.graduateFromCountry}
															onChange={handleChange}
															value={values.graduateFromCountry}
														>
															<option value="">---Select---</option>
															{COUNTRIES_LIST.map((countries, index) => (
																<option
																	key={"countries" + index}
																	value={countries?.en_short_name}
																>
																	{countries?.en_short_name}
																</option>
															))}
														</Form.Control>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Province/State <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															placeholder="Please input graduate province"
															value={values.graduateFromProvince}
															name="graduateFromProvince"
															isInvalid={errorInput && errorInput?.graduateFromProvince}
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Graduate from <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															placeholder="Please input graduate from"
															value={values.graduateFromSchool}
															name="graduateFromSchool"
															isInvalid={errorInput && errorInput?.graduateFromSchool}
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Academic year <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															name="semesterYear"
															isInvalid={errorInput && errorInput?.semesterYear}
															onChange={handleChange}
															value={values.semesterYear}
														>
															{/* <option>---Select---</option> */}
															{graduatedYear.map((x, index) => {
																return (
																	<option value={x} key={index}>
																		{x}
																	</option>
																);
															})}
														</Form.Control>
													</div>

													<div className="col-md-6 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Transfer letter no
														</Form.Label>
														<Form.Control
															type="text"
															name="transferLetterNo"
															placeholder="Please input transfer letter no"
															onChange={handleChange}
															value={values.transferLetterNo}
															maxLength="20"
														/>
													</div>
													<div className="col-md-6 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Date
														</Form.Label>
														<Form.Control
															type="date"
															value={values.dateTransferLetterNo}
															name="dateTransferLetterNo"
															onChange={handleChange}
														/>
													</div>
													<div className="col-md-6 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Scholarship agreement no
														</Form.Label>
														<Form.Control
															type="text"
															placeholder="Please input scholarship agreement no"
															name="scholarshipAgreementNo"
															value={values.scholarshipAgreementNo}
															onChange={handleChange}
															maxLength="20"
														/>
													</div>
													<div className="col-md-6 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Date
														</Form.Label>
														<Form.Control
															type="date"
															name="dateScholarshipAgreementNo"
															onChange={handleChange}
															value={values.dateScholarshipAgreementNo}
														/>
													</div>
													<div className="col-md-12 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Other scholarship{" "}
															<span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															name="otherScholarship"
															placeholder="Please input other scholarship"
															isInvalid={errors.otherScholarship}
															onChange={handleChange}
															value={values.otherScholarship}
															maxLength="50"
															disabled={
																scholarship == ""
																	? true
																	: scholarship == "OTHER_SHOLARSHIP"
																		? false
																		: true
															}
														/>
													</div>
												</div>
											</div>
										)}
										{dataLocalStorages?.educationLevel === "BACHELOR_CONTINUING" ? (
											<>
												<div className="col-md-4 mt-3">
													<Form.Label fontSize="14" className="text-bold">
														Graduated Major <span className="text-danger">*</span>
													</Form.Label>
													<Form.Control
														type="text"
														name="graduatedMajor"
														placeholder="Please input graduated Major"
														isInvalid={
															errorInput && errorInput?.graduatedMajor
														}
														onChange={handleChange}
														value={values.graduatedMajor}
													/>
												</div>
											</>
										) :
											(
												<>
												</>
											)
										}
										<div className="card bg-register mt-5"><b>Parents's information</b></div>
										<div className="row margin-resgintrationStudent">
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Father name{" "}
													<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="Please input father name"
													name="fatherNameAndSurname"
													isInvalid={errorInput && errorInput?.fatherNameAndSurname}
													onChange={handleChange}
													value={values.fatherNameAndSurname}
													maxLength="40"
												/>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Mother name{" "}
													<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="Please input mother name"
													name="motherNameAndSurname"
													isInvalid={errorInput && errorInput?.motherNameAndSurname}
													onChange={handleChange}
													value={values.motherNameAndSurname}
													maxLength="40"
												/>
											</div>
											<div className="col-md-4 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Mobile phone <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="tel"
													placeholder="Please input mobile phone"
													name="parentTelephone"
													isInvalid={errorInput && errorInput?.parentTelephone}
													onChange={handleParentTelephoneChange}
													value={parentTelephone}
													maxLength="15"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Country <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													as="select"
													name="parentCountry"
													onChange={handleChange}
													isInvalid={errorInput && errorInput?.parentCountry}
													value={values.parentCountry}
												>
													<option value="">---Select---</option>
													{COUNTRIES_LIST.map((countries, index) => (
														<option
															key={"countries" + index}
															value={countries?.en_short_name}
														>
															{countries?.en_short_name}
														</option>
													))}
												</Form.Control>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Province/State <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="parentProvince"
													value={values.parentProvince}
													isInvalid={errorInput && errorInput?.parentProvince}
													placeholder="Please input province"
													onChange={handleChange}
													maxLength="50"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													District <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="parentDistrict"
													value={values.parentDistrict}
													isInvalid={errorInput && errorInput?.parentDistrict}
													placeholder="Please input district"
													onChange={handleChange}
													maxLength="50"
												/>
											</div>
											<div className="col-md-3 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Village <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="parentVillage"
													value={values.parentVillage}
													isInvalid={errorInput && errorInput?.parentVillage}
													placeholder="Please input village"
													onChange={handleChange}
													maxLength="50"
												/>
											</div>
										</div>
										<div className="card bg-register mt-5"><b>Emergency contact</b></div>
										<div className="row margin-resgintrationStudent">
											<div className="col-md-6 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Name <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													value={values.contactName}
													name="contactName"
													isInvalid={errorInput && errorInput?.contactName}
													placeholder="Please input name"
													onChange={handleChange}
													maxLength="50"
												// disabled
												/>
											</div>
											<div className="col-md-6 mt-3">
												<Form.Label fontSize="14" className="text-bold">
													Mobile phone <span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="tel"
													value={emergencyPhone}
													name="emergencyPhone"
													isInvalid={errorInput && errorInput?.emergencyPhone}
													placeholder="​Please input mobile phone"
													onChange={handleEmergencyPhoneChange}
													maxLength="15"
												// disabled
												/>
											</div>
											<div className="col-md-12 mt-3">
												<Form.Group
													controlId="formBasicCheckbox"
													style={{ marginTop: 25 }}
												>
													<Form.Check
														type="checkbox"
														label="Same address"
														name="stayTogether"
														value={values.stayTogether}
														onChange={handleChange}
														
														checked={values.stayTogether === true ? true : false}
														onClick={() => _clickStayTogether()}
													/>
												</Form.Group>
											</div>
											{stayTogether === false ? (
												<>
													<Form.Label fontSize="14" className="text-bold">
														<span className="text-danger">*</span> Note: if not staying together. Please input the address below
													</Form.Label>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Country <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															as="select"
															name="emergencyCountry"
															onChange={handleChange}
															isInvalid={errorInput && errorInput?.emergencyCountry}
															value={values.emergencyCountry}
														>
															<option value="">---Select---</option>
															{COUNTRIES_LIST.map((countries, index) => (
																<option
																	key={"countries" + index}
																	value={countries?.en_short_name}
																>
																	{countries?.en_short_name}
																</option>
															))}
														</Form.Control>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Province/State <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															name="emergencyProvince"
															value={values.emergencyProvince}
															isInvalid={errorInput && errorInput?.emergencyProvince}
															placeholder="Please input province"
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															District <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															name="emergencyDistrict"
															value={values.emergencyDistrict}
															isInvalid={errorInput && errorInput?.emergencyDistrict}
															placeholder="Please input district"
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
													<div className="col-md-3 mt-3">
														<Form.Label fontSize="14" className="text-bold">
															Village <span className="text-danger">*</span>
														</Form.Label>
														<Form.Control
															type="text"
															name="emergencyVillage"
															value={values.emergencyVillage}
															isInvalid={errorInput && errorInput?.emergencyVillage}
															placeholder="Please input village"
															onChange={handleChange}
															maxLength="50"
														/>
													</div>
												</>
											)
												:
												(<></>)
											}
										</div>
										<div className="card bg-register mt-5">
											<label>
												<b>Upload document</b> <span className="text-danger">*</span>
											</label>
										</div>
										{/* <div style={{ marginTop: 10 }}>ສາມາດອັບໂຫຼດຟາຍຮູບໄດ້ສະເພາະນາມສະກຸນ: .png, .heif, .jpeg, .jpg, .pdf ເທົ່ານັ້ນ</div> */}
										<div className="row margin-resgintrationStudent">
											<div className="col-md-6 mt-3">
												Passport{" "}
												<span className="text-danger">*</span>
											</div>
											<div className="col-md-6 mt-3">
												<div
													className="col-sm-12 center"
													style={{
														border: "1px solid #ddd",
														outline: "none",
														cursor: "pointer",
														textAlign: "center",
													}}
												>
													<input
														type="file"
														id="file-upload"
														onChange={_handleChangeFile}
														hidden
														accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
														onClick={() => setClick("1")}
													/>
													<label
														htmlFor="file-upload"
														style={{ width: "100%", cursor: "pointer" }}
													>
														<div
															style={{
																backgroundColor: "#f3f3f3",
																marginLeft: 20,
																marginTop: 5,
																marginRight: 20,
																textAlign: "center",
															}}
														>
															{click === "1" && fileUploadProgress1 > 0 && fileUploadProgress1 < 100 ?
																<div>
																	<h3>Uploading file....</h3>
																	<ProgressBar animated now={fileUploadProgress1} label={`${fileUploadProgress1}%`} />
																</div>
																:
																<FontAwesomeIcon icon={faUpload} size="2x" />
															}
														</div>
													</label>
													<span>Choose file</span>
													{passportUrl && fileUploadProgress1 === 0 && (
														<aside>
															<h4>Your file</h4>
															{passport}
															<div
																style={{
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<Button
																	onClick={() => {
																		setPassport(null);
																		setPassportUrl(null);
																	}}
																	variant={"danger"}
																>
																	Delete file
																</Button>
															</div>
														</aside>
													)}
												</div>
											</div>
											<div className="col-md-6 mt-3">
												Qualification achieved abroad{" "}
												<span className="text-danger">*</span>
											</div>
											<div className="col-md-6 mt-3">
												<div
													className="col-sm-12 center"
													style={{
														border: "1px solid #ddd",
														outline: "none",
														cursor: "pointer",
														textAlign: "center",
													}}
												>
													<input
														type="file"
														id="file-upload3"
														accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
														onChange={_handleChangeFile}
														onClick={() => setClick("2")}
														hidden
													/>
													<label
														htmlFor="file-upload3"
														style={{ width: "100%", cursor: "pointer" }}
													>
														<div
															style={{
																backgroundColor: "#f3f3f3",
																marginLeft: 20,
																marginTop: 5,
																marginRight: 20,
																textAlign: "center",
															}}
														>
															{click === "2" && fileUploadProgress2 > 0 && fileUploadProgress2 < 100 ?
																<div>
																	<h3>Uploading file....</h3>
																	<ProgressBar animated now={fileUploadProgress2} label={`${fileUploadProgress2}%`} />
																</div>
																:
																<FontAwesomeIcon icon={faUpload} size="2x" />
															}
														</div>
													</label>
													<span>Choose file</span>
													{qualificationUrl && fileUploadProgress2 === 0 && (
														<aside>
															<h4>Your file</h4>
															{qualification}
															<div
																style={{
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<Button
																	onClick={() => {
																		setQualification(null);
																		setQualificationUrl(null);
																	}}
																	variant={"danger"}
																>
																	Delete file
																</Button>
															</div>
														</aside>
													)}
												</div>
											</div>
											<div className="col-md-6 mt-3">
												Other{" "}
											</div>
											<div className="col-md-6 mt-3">
												<div
													className="col-sm-12 center"
													style={{
														border: "1px solid #ddd",
														outline: "none",
														cursor: "pointer",
														textAlign: "center",
													}}
												>
													<input
														type="file"
														id="file-upload4"
														accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
														onChange={_handleChangeFile}
														onClick={() => setClick("3")}
														hidden
													/>
													<label
														htmlFor="file-upload4"
														style={{ width: "100%", cursor: "pointer" }}
													>
														<div
															style={{
																backgroundColor: "#f3f3f3",
																marginLeft: 20,
																marginTop: 5,
																marginRight: 20,
																textAlign: "center",
															}}
														>
															{click === "3" && fileUploadProgress3 > 0 && fileUploadProgress3 < 100 ?
																<div>
																	<h3>Uploading file....</h3>
																	<ProgressBar animated now={fileUploadProgress3} label={`${fileUploadProgress3}%`} />
																</div>
																:
																<FontAwesomeIcon icon={faUpload} size="2x" />
															}
														</div>
													</label>
													<span>Choose file</span>
													{otherUrl && fileUploadProgress3 === 0 && (
														<aside>
															<h4>Your file</h4>
															{other}
															<div
																style={{
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<Button
																	onClick={() => {
																		setOther(null);
																		setOtherUrl(null);
																	}}
																	variant={"danger"}
																>
																	Delete file
																</Button>
															</div>
														</aside>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="row fixed-bottom py-3" style={{ backgroundColor: "#fff", marginBottom: 0 }}>
										<div className="col-xs-6 col-md-4 col-4 text-center">
											<Button
												style={{ paddingLeft: 10, paddingRight: 10 }}
												className="buttonoutline"
												onClick={() => setShowConfirmLogout(true)}
											>
												Cancel
											</Button>
										</div>
										<div className="col-xs-6 col-md-4 col-4 text-center">
											<Button
												type="submit"
												className="btn btn-success"
												onClick={() => {
													setType("save");
													// handleSubmit()
												}}
												style={{ backgroundColor: "#1B8900", color: "#fff" }}
												disabled={isLoading ? true : false}
											>
												Save
											</Button>
										</div>
										<div className="col-xs-6 col-md-4 col-4 text-center">
											<Button
												type="submit"
												className="btn btn-primary"
												onClick={() => {
													setType("verify");
													// handleSubmit();
												}}
												disabled={isSave == true ? true : (isLoading ? true : false)}
											>
												Send <FontAwesomeIcon icon={faPaperPlane} />
											</Button>

										</div>
									</div>
								</div>
							</form>
						)}
					</Formik>
				}
			</div>
			<Modal
				show={show}
				onHide={() => isSaving ? console.log("Saving") : setShow(false)}
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Body>
					{isSaving ? <Col
						className="text-center"
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<Spinner animation="border" style={{ color: "#057CAE" }} />
					</Col> : <Col
						className="text-center"
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<div
							className="textfont-size40 text-bold"
							style={{ color: "#00A1DE" }}
						>
							Successfully
						</div>
						<FontAwesomeIcon
							icon={faCheckCircle}
							size="9x"
							style={{ color: "#00A1DE" }}
						/>
						<div
							className="textfont-size19 text-bold"
							style={{ marginTop: 40 }}
						>
							Your information have saved
						</div>
					</Col>}
				</Modal.Body>
			</Modal>

			<Modal show={showDanger} onHide={() => setShowDanger(false)} centered>
				<Modal.Body>
					<Col
						className="text-center"
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<div className="textfont-size40 text-bold" style={{ color: "red" }}>
							Incomplete information!
						</div>
						<FontAwesomeIcon
							icon={faTimesCircle}
							size="5x"
							style={{ color: "red" }}
						/>
						<div
							className="textfont-size18 text-bold"
							style={{ marginTop: 40 }}
						>
							Check your information!
						</div>
					</Col>
				</Modal.Body>
			</Modal>

			<Modal
				style={{ marginTop: 50 }}
				show={showConfirmLogout}
				onHide={() => setShowConfirmLogout(false)}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>
							Exit
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Col
						className="text-center"
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<div
							// className="text-resize5"
							style={{ fontWeight: "bold", fontSize: 24, color: "#6f6f6f" }}
						>
							Are you sure?
						</div>
					</Col>
					<Col className="d-flex justify-content-between">
						<div />
						<CustomButton
							onClick={() => setShowConfirmLogout(false)}
							type="submit"
							title="Cancel"
						/>

						<CustomButton
							confirm
							onClick={() => _onLogout()}
							type="submit"
							title="Yes, I'm sure"
						/>
						<div />
					</Col>
				</Modal.Body>
			</Modal>
			<RegistrationConfirmEn
				showAddConfirmModal={showAddConfirmModal}
				studentType={studentType}
				param={formParam}
				_handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
			/>
		</div>
	);
}
