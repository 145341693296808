import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import useReactRouter from "use-react-router";
import { Col, Modal, Button } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from '../../common/Loading'
import {
	CREATE_STUDENT,
	UPDATE_STUDENT,
} from "../../apollo/registrationstudent";
import { onConvertMaritualStatusEn, onConvertStudentInfo, onConvertEducationLevel, onConvertGenderEn, convertGenderEn, convertHealthStatusEn } from "../../common/super";
import { STUDENTS, TRIBES } from "../../apollo/students";

// CourseAddConfirm
function RegistrationConfirmEn({
	param,
	studentType,
	showAddConfirmModal,
	_handleShowAddConfirmModalClose,
}) {
	const { history } = useReactRouter();
	const [dataQuey, setDataQuey] = useState();
	const [createStudent, { loading }] = useMutation(
		CREATE_STUDENT
	);
	const [loadeTribe, { data: dataTribe }] = useLazyQuery(TRIBES);
	const [updateStudent, { data: studentData }] = useMutation(UPDATE_STUDENT);
	const [show, setShow] = useState(false);
	const [tribeName, setTribeName] = useState('');
	const [assessmentYear, setAssessmentYear] = useState("")
	const [loadStudentData, { data: dataStudent }] = useLazyQuery(STUDENTS);

	useEffect(() => {
		const NOW = new Date().getFullYear();
		const checkMonth = new Date().getMonth() + 1
		let year
		if (checkMonth === 8 || checkMonth === 9 || checkMonth === 10 || checkMonth === 11 || checkMonth === 12) {
			year = NOW + " - " + (parseInt(NOW) + 1);
		} else {
			year = (NOW - 1) + " - " + NOW;
		}
		setAssessmentYear("2024 - 2025")
		// setAssessmentYear(year)

	}, [])

	useEffect(() => {
		if (_.isEmpty(param) === true) return;
		loadStudentData({
			variables: {
				where: { code: param?.data?.code, assessmentYear: assessmentYear },
			},
		});
		loadeTribe()
	}, [param]);

	useEffect(() => {
		setDataQuey(dataStudent?.students?.data[0]);
		const index = _.findIndex(dataTribe?.tribes, {
			id: param?.data?.tribe
		})
		setTribeName(dataTribe?.tribes[index]?.trib_name)
	}, [dataStudent, dataTribe]);

	// SEND_FORM
	const confirmRegistration = async () => {

		try {
			if (dataQuey?.statusStep === "SAVED") {
				delete param.data.facultyname;
				delete param.data.departmentname;
				delete param.data.majorname;
				// delete param.data.statusStepUpdate;
				delete param.data.statusStep
				let _data = {
					...param.data, statusStep: "SEND_FORM"
				}
				const updateData = await updateStudent({
					variables: {
						where: { id: dataQuey?.id },
						data: _data,
					},
				});
				if (updateData?.data?.updateStudent?.id) {
					_handleShowAddConfirmModalClose();
					setShow(true);
					setTimeout(() => {
						setShow(false);
						history.push(`/cardstudentEn/${param?.data?.code}`);
						window.location.reload();
					}, 1000);
				}
			} else if (dataQuey?.statusStep === "REJECTED") {
				delete param.data.facultyname;
				delete param.data.departmentname;
				delete param.data.majorname;
				// delete param.data.statusStepUpdate;
				delete param.data.statusStep
				let _data = {
					...param.data, statusStep: "SEND_FORM"
				}
				const updateData = await updateStudent({
					variables: {
						where: { id: dataQuey?.id },
						data: _data,
					},
				});
				if (updateData?.data?.updateStudent?.id) {
					_handleShowAddConfirmModalClose();
					setShow(true);
					setTimeout(() => {
						setShow(false);
						history.push(`/cardstudentEn/${param?.data?.code}`);
						window.location.reload();
					}, 1000);
				}
			} else {
				delete param.data.facultyname;
				delete param.data.departmentname;
				delete param.data.majorname;
				// delete param.data.statusStepUpdate;
				const createNewStudent = await createStudent({ variables: param });
				if (createNewStudent?.data?.createStudent?.id) {
					_handleShowAddConfirmModalClose();
					setShow(true);
					setTimeout(() => {
						setShow(false);
						history.push(`/cardstudentEn/${param?.data?.code}`);
						window.location.reload();
					}, 1000);
				}
			}
		} catch (err) {
			console.error("error:", err.message);
			if (err.message == "GraphQL error: EMAIL_IS_ALREADY_EXITS") {
				alert("This email is already exits");
			}
			if (err.message == "GraphQL error: PHONE_NUMBER_IS_ALREADY_EXITS") {
				alert("This phone number is already exits");
			}
		}
	};
	if (loading) return <Loading />
	return (
		<div>
			<Modal
				// style={{ marginTop: 50 }}
				show={showAddConfirmModal}
				onHide={_handleShowAddConfirmModalClose}
				size="md"
				centered
			>
				<Modal.Header closeButton>
					<Col
						style={{
							marginTop: 20,
							fontSize: 30,
							fontWeight: "bold",
							color: "#057CAE",
						}}
					>
						Confirmation
					</Col>
				</Modal.Header>
				<Modal.Body>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> Student's information
						</li>
						<li className="list-group-item">
							Registration Number:{" "}
							<span className="float-right">{param?.data?.studentNumber}</span>
						</li>
						<li className="list-group-item">
							Faculty:{" "}
							<span className="float-right">{param?.data?.facultyname}</span>
						</li>
						<li className="list-group-item">
							Department:{" "}
							<span className="float-right">{param?.data?.departmentname}</span>
						</li>
						<li className="list-group-item">
							Major:{" "}
							<span className="float-right">{param?.data?.majorname}</span>
						</li>
						<li className="list-group-item">
							Student's:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.studentInfo
										? onConvertStudentInfo(param?.data?.studentInfo)
										: "-")}
							</span>
						</li>
						<li className="list-group-item">
							Level:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.educationLevel
										? onConvertEducationLevel(param?.data?.educationLevel)
										: "-")}
							</span>
						</li>
						<ul className="list-group list-group-flush">
							<li
								className="list-group-item bg-active"
								style={{ color: "#fff", fontWeight: "bold" }}
							>
								<FontAwesomeIcon icon={faCaretDown} /> General information
							</li>
						</ul>
						<li className="list-group-item">
							Given name{" "}
							<span className="float-right">{onConvertGenderEn(param?.data?.gernder)} {param?.data?.firstNameE}</span>
						</li>
						<li className="list-group-item">
							Family name{" "}
							<span className="float-right">{param?.data?.lastNameE}</span>
						</li>
						<li className="list-group-item">
							Date of birth:{" "}
							<span className="float-right">{param?.data?.birthday}</span>
						</li>
						<li className="list-group-item">
							Health:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.healthStatus
										? convertHealthStatusEn(param?.data?.healthStatus)
										: "-")}
							</span>
						</li>
						{param && param?.data?.healthStatus === "BAD" ?
							<li className="list-group-item">
								<span>
									{param &&
										(param?.data?.health
											? param?.data?.health
											: "-")}
								</span>
							</li> : ""
						}
						<li className="list-group-item">
							Gender:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.gender ? convertGenderEn(param?.data?.gender) : "-")}
							</span>
						</li>
						<li className="list-group-item">
							Marital staus:{" "}
							<span className="float-right">
								{param &&
									(param?.data?.maritualStatus
										? onConvertMaritualStatusEn(param?.data?.maritualStatus)
										: "-")}
							</span>
						</li>
						<li className="list-group-item">
							Nationality:{" "}
							<span className="float-right">{param?.data?.nationality}</span>
						</li>
						<li className="list-group-item">
							Religion:{" "}
							<span className="float-right">{param?.data?.religion}</span>
						</li>
						<li className="list-group-item">
							Home phone:{" "}
							<span className="float-right">{param?.data?.phoneHome}</span>
						</li>
						<li className="list-group-item">
							Telephone number:{" "}
							<span className="float-right">{param?.data?.phoneMobile}</span>
						</li>
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> Current resident
						</li>
						<li className="list-group-item">
							Country:{" "}
							<span className="float-right">
								{param?.data?.address?.province}
							</span>
						</li>
						<li className="list-group-item">
							Province/State:{" "}
							<span className="float-right">
								{param?.data?.address?.province}
							</span>
						</li>
						<li className="list-group-item">
							District:{" "}
							<span className="float-right">
								{param?.data?.address?.district}
							</span>
						</li>
						<li className="list-group-item">
							Village:{" "}
							<span className="float-right">
								{param?.data?.address?.village}
							</span>
						</li>
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> Birth place
						</li>
						<li className="list-group-item">
							Country:{" "}
							<span className="float-right">
								{param?.data?.birthAddress?.province}
							</span>
						</li>
						<li className="list-group-item">
							Province/State:{" "}
							<span className="float-right">
								{param?.data?.birthAddress?.province}
							</span>
						</li>
						<li className="list-group-item">
							District:{" "}
							<span className="float-right">
								{param?.data?.birthAddress?.district}
							</span>
						</li>
						<li className="list-group-item">
							Village:{" "}
							<span className="float-right">
								{param?.data?.birthAddress?.village}
							</span>
						</li>
					</ul>
					<ul className="list-group list-group-flush">
						{studentType !== "IN_PLAN_EN" ? (
							<>
								<li
									className="list-group-item bg-active"
									style={{ color: "#fff", fontWeight: "bold" }}
								>
									<FontAwesomeIcon icon={faCaretDown} />{" "}
									For self-financing student
								</li>
								<li className="list-group-item">
									Country:{" "}
									<span className="float-right">
										{param?.data?.graduateFromCountry}
									</span>
								</li>
								<li className="list-group-item">
									Province/State:{" "}
									<span className="float-right">
										{param?.data?.graduateFromProvince}
									</span>
								</li>
								<li className="list-group-item">
									Graduate from:{" "}
									<span className="float-right">
										{param?.data?.graduateFromSchool}
									</span>
								</li>
								<li className="list-group-item">
									Academic year:{" "}
									<span className="float-right">{param?.data?.semesterYear}</span>
								</li>
							</>
						) : (
							<>
								<li
									className="list-group-item bg-active"
									style={{ color: "#fff", fontWeight: "bold" }}
								>
									<FontAwesomeIcon icon={faCaretDown} /> For student/Civil servant who receives scholarship from an organization
								</li>
								<li className="list-group-item">
									Country:{" "}
									<span className="float-right">
										{param?.data?.graduateFromProvince}
									</span>
								</li>
								<li className="list-group-item">
									Province:{" "}
									<span className="float-right">
										{param?.data?.graduateFromProvince}
									</span>
								</li>
								<li className="list-group-item">
									Graduate from:{" "}
									<span className="float-right">
										{param?.data?.graduateFromSchool}
									</span>
								</li>
								<li className="list-group-item">
									Graduate year:{" "}
									<span className="float-right">{param?.data?.semesterYear}</span>
								</li>
							</>
						)}
						{param?.data?.educationLevel === "BACHELOR_CONTINUING" ? (
							<>
								<li className="list-group-item">
									ຈົບສາຂາວິຊາ:{" "}
									<span className="float-right">
										{param?.data?.graduatedMajor}
									</span>
								</li>
							</>
						) :
							(<></>)
						}
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> Emergency contact
						</li>
						<li className="list-group-item">
							Name and surname:{" "}
							<span className="float-right">{param?.data?.contactName}</span>
						</li>
						<li className="list-group-item">
							Telephone number:{" "}
							<span className="float-right">{param?.data?.emergencyPhone}</span>
						</li>
						<li className="list-group-item">
							Living with:{" "}
							<span className="float-right">
								{param?.data?.stayTogether == true ? "Yes" : "No"}
							</span>
						</li>
						{param?.data?.stayTogether == false ? (
							<>
								<li className="list-group-item">
									Country:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.country}
									</span>
								</li>
								<li className="list-group-item">
									Province/State:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.province}
									</span>
								</li>
								<li className="list-group-item">
									District:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.district}
									</span>
								</li>
								<li className="list-group-item">
									Village:{" "}
									<span className="float-right">
										{param?.data?.emergencyAddress?.village}
									</span>
								</li>
							</>
						) : (<></>)
						}
					</ul>
					<ul className="list-group list-group-flush">
						<li
							className="list-group-item bg-active"
							style={{ color: "#fff", fontWeight: "bold" }}
						>
							<FontAwesomeIcon icon={faCaretDown} /> Attach files
						</li>
						<li className="list-group-item">
							National ID or passport:{" "}
							<span className="float-right">
								{param?.data?.passportUrl?.url}
							</span>
						</li>
						<li className="list-group-item">
							Graduation certificate{" "}
							<span className="float-right">
								{param?.data?.qualificationUrl?.url}
							</span>
						</li>
						<li className="list-group-item">
							Other{" "}
							<span className="float-right">
								{param?.data?.otherUrl?.url}
							</span>
						</li>
					</ul>
					<hr />
					<div className="button-confirm">
						<center>
							<Button
								type="submit"
								className="buttonoutline "
								onClick={() => _handleShowAddConfirmModalClose()}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								className="btn btn-default bg-active button-apply-confirm marign-button-apply-right"
								onClick={() => confirmRegistration()}
							>
								Confirm
							</Button>
						</center>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={show} onHide={() => setShow(false)} centered>
				<Modal.Body>
					<Col
						className="text-center"
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<div
							className="textfont-size40 text-bold"
							style={{ color: "#00A1DE" }}
						>
							Successfully
						</div>
						<FontAwesomeIcon
							icon={faCheckCircle}
							size="9x"
							style={{ color: "#00A1DE" }}
						/>
						<div
							className="textfont-size19 text-bold"
							style={{ marginTop: 40 }}
						>
							Your information have saved
						</div>
					</Col>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default RegistrationConfirmEn;
